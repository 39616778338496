import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './TransferenciaModal.css'; // Seguindo o padrão de estilo

const TransferenciaModal = ({ isOpen, onClose }) => {
    const [carteiraOrigem, setCarteiraOrigem] = useState('');  // O ID da carteira de origem
    const [carteiraDestino, setCarteiraDestino] = useState('');  // O ID da carteira de destino
    const [valor, setValor] = useState('');
    const [carteiras, setCarteiras] = useState([]);
    const [carteirasDestino, setCarteirasDestino] = useState([]);
    const [isClosing, setIsClosing] = useState(false);
    const [isSameCarteira, setIsSameCarteira] = useState(false);

    // Carregar carteiras quando o modal for aberto
    useEffect(() => {
        if (isOpen) {
            const userId = localStorage.getItem('userId');
            fetch(`/api/users/${userId}/carteiras`)
                .then(res => res.json())
                .then(data => {
                    if (data && data.carteiras) {
                       // console.log("Carteiras carregadas:", data.carteiras);
                        setCarteiras(data.carteiras); // Carrega todas as carteiras
                        setCarteirasDestino(data.carteiras); // Inicializa também as carteiras de destino
                    } else {
                        console.error("Erro ao carregar carteiras:", data.message);
                        setCarteiras([]);
                    }
                })
                .catch(err => {
                    console.error('Erro ao buscar carteiras:', err);
                    setCarteiras([]);
                });
        }
    }, [isOpen]);

    // Atualizar a carteira de origem e filtrar a lista de carteiras de destino
    const handleOrigemChange = (e) => {
        const origemId = e.target.value; // Armazena o ID da carteira
       // console.log("Carteira de Origem Selecionada (ID):", origemId);  // Agora estamos logando o ID da carteira
        setCarteiraOrigem(origemId);
    
        // Filtrar as carteiras de destino para que a carteira de origem não apareça
        const carteirasFiltradas = carteiras.filter(c => c.id !== parseInt(origemId)); // Comparar pelo ID
        setCarteirasDestino(carteirasFiltradas);
    
        // Verificar se a carteira de destino é igual à origem
        if (carteiraDestino === origemId) {
            setIsSameCarteira(true);
        } else {
            setIsSameCarteira(false);
        }
    };

    // Atualizar a carteira de destino
    const handleDestinoChange = (e) => {
        const destinoId = e.target.value; // Armazena o ID da carteira
       // console.log("Carteira de Destino Selecionada (ID):", destinoId);  // Agora estamos logando o ID da carteira
        setCarteiraDestino(destinoId);
    
        // Verificar se a carteira de destino é igual à origem
        if (carteiraOrigem === destinoId) {
            setIsSameCarteira(true); // Desabilita o campo de valor
        } else {
            setIsSameCarteira(false); // Habilita o campo de valor
        }
    };

    // Função para formatar o valor como moeda
    const formatarValor = (valor) => {
        let val = valor.replace(/\D/g, ''); // Remove todos os caracteres que não são números
        val = (val / 100).toFixed(2); // Insere os decimais corretamente
        val = val.replace('.', ','); // Troca o ponto por vírgula
        val = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Adiciona o separador de milhares
        return val;
    };

    // Função para lidar com a mudança no campo de valor e formatar corretamente
    const handleValorChange = (e) => {
        const valorFormatado = formatarValor(e.target.value);
        setValor(valorFormatado);
    };

    // Enviar a transferência
    const handleSubmit = (e) => {
        e.preventDefault();
        const userId = localStorage.getItem('userId');
        
        if (!carteiraOrigem || !carteiraDestino || !valor) {
            toast.error('Preencha todos os campos obrigatórios.');
            return;
        }
    
        const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
    
        fetch(`/api/users/${userId}/transferencia`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                carteiraOrigemId: carteiraOrigem,
                carteiraDestinoId: carteiraDestino,
                valor: valorNumerico
            }),
        })
        .then((res) => res.json())
        .then((data) => {
            if (data.message === 'Transferência realizada com sucesso.') {
                toast.success('Transferência realizada com sucesso!', {
                    autoClose: 5000 // Exibe o toast por 5 segundos
                });
    
                // Adiciona um atraso no fechamento do modal para garantir que o toast seja exibido
                setTimeout(() => {
                    handleClose();
                }, 10); // Fechar o modal após o toast ser exibido completamente
            } else {
                toast.error(data.message);
            }
        })
        .catch((err) => {
            console.error('Erro ao realizar a transferência:', err);
            toast.error('Erro ao realizar a transferência.');
        });
    };
    

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('transferencia-modal')) {
            handleClose();
        }
    };

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
          onClose(); // Fecha o modal
          setIsClosing(false);
          window.location.reload(); // Recarrega a página após o modal fechar
        }, 4000); // 300ms de delay para garantir o efeito de fechamento
      };
      

    return (
        <>
            <div className={`transferencia-modal ${isOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleOutsideClick}>
                <div className="transferencia-modal-content">
                    <button className="close-btn" onClick={handleClose}>&times;</button>
                    <h2>Transferência entre Carteiras</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Carteira de Origem:</label>
                            <select value={carteiraOrigem} onChange={handleOrigemChange} required>
                                <option value="">Selecione</option>
                                {carteiras.map((c, index) => (
                                    <option key={c.id || index} value={c.id}> {/* O valor é o ID da carteira */}
                                        {`${c.nome} - Saldo: ${c.saldo_atual.toLocaleString('pt-BR', { style: 'currency', currency: c.moeda })}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Carteira de Destino:</label>
                            <select value={carteiraDestino} onChange={handleDestinoChange} required disabled={!carteiraOrigem}>
                                <option value="">Selecione</option>
                                {carteirasDestino.map((c, index) => (
                                    <option key={c.id || index} value={c.id}> {/* O valor é o ID da carteira */}
                                        {`${c.nome} - Saldo: ${c.saldo_atual.toLocaleString('pt-BR', { style: 'currency', currency: c.moeda })}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {isSameCarteira && (
                            <div className="alert alert-danger">
                                Não é permitido transferir entre a mesma carteira de origem e destino.
                            </div>
                        )}
                        <div className="form-group">
                            <label>Valor da Transferência:</label>
                            <input
                                type="text"
                                value={valor}
                                onChange={handleValorChange}
                                required
                                disabled={isSameCarteira}
                                placeholder="0,00"
                            />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary" type="submit" disabled={isSameCarteira}>Transferir</button>
                            <button className="btn btn-secondary" type="button" onClick={handleClose}>Cancelar</button>
                        </div>
                    </form>
                </div>
            </div>


        </>
    );
};

export default TransferenciaModal;
