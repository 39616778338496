import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCar,
  faHome,
  faQuestionCircle,
  faHeart,
  faGraduationCap,
  faPlane,
  faBriefcase,
  faMoneyBillWave,
  faPiggyBank,
  faUmbrella,
  faSeedling,
  faRedo,
  faCouch,
  faDumbbell,
  faChartLine,
  faMobileAlt,
  faUserFriends,
  faGift,
  faEdit,
  faTrashAlt,
  faHeartbeat 
} from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import './metas.css'; 

const Metas = () => {
  const [metas, setMetas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMeta, setSelectedMeta] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false); 

  useEffect(() => {
    const fetchMetas = async () => {
      const userId = localStorage.getItem('userId');

      if (!userId) {
        console.error('User ID não encontrado.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`/api/users/${userId}/metas`);
        if (!response.ok) {
          throw new Error('Erro ao buscar metas.');
        }
        const data = await response.json();
        setMetas(data.metas || []);
      } catch (error) {
        console.error('Erro ao buscar as metas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetas();
  }, []);

  const normalizeString = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  const getIconByDescription = (descricao) => {
    const normalizedDescription = normalizeString(descricao);

    if (normalizedDescription.includes('carro') || normalizedDescription.includes('automovel') || normalizedDescription.includes('veiculo')) {
      return faCar;
    }
    if (
      normalizedDescription.includes('imovel') ||
      normalizedDescription.includes('casa') ||
      normalizedDescription.includes('terreno') ||
      normalizedDescription.includes('apartamento') ||
      normalizedDescription.includes('condominio') ||
      normalizedDescription.includes('residencia')
    ) {
      return faHome;
    }
    if (
      normalizedDescription.includes('dívida') ||
      normalizedDescription.includes('dividas') ||
      normalizedDescription.includes('empréstimo') ||
      normalizedDescription.includes('emprestimos')
    ) {
      return faMoneyBillWave;
    }
    if (
      normalizedDescription.includes('emergencia') ||
      normalizedDescription.includes('urgencia') ||
      normalizedDescription.includes('contingencia')
    ) {
      return faHeartbeat;
    }
    if (
      normalizedDescription.includes('aposentadoria') ||
      normalizedDescription.includes('aposentar') ||
      normalizedDescription.includes('previdencia')
    ) {
      return faUmbrella;
    }
    if (
      normalizedDescription.includes('viagem') ||
      normalizedDescription.includes('viajar') ||
      normalizedDescription.includes('férias') ||
      normalizedDescription.includes('ferias') ||
      normalizedDescription.includes('turismo')
    ) {
      return faPlane;
    }
    if (
      normalizedDescription.includes('educacao') ||
      normalizedDescription.includes('faculdade') ||
      normalizedDescription.includes('universidade') ||
      normalizedDescription.includes('curso')
    ) {
      return faGraduationCap;
    }
    if (
      normalizedDescription.includes('investimento') ||
      normalizedDescription.includes('poupanca') ||
      normalizedDescription.includes('tesouro') ||
      normalizedDescription.includes('ações') ||
      normalizedDescription.includes('bolsa')
    ) {
      return faPiggyBank;
    }
    if (
      normalizedDescription.includes('reforma') ||
      normalizedDescription.includes('renovação') ||
      normalizedDescription.includes('construcao') ||
      normalizedDescription.includes('obra')
    ) {
      return faRedo;
    }
    if (
      normalizedDescription.includes('filho') ||
      normalizedDescription.includes('filhos') ||
      normalizedDescription.includes('criança') ||
      normalizedDescription.includes('criancas') ||
      normalizedDescription.includes('bebe')
    ) {
      return faUserFriends;
    }
    if (
      normalizedDescription.includes('casamento') ||
      normalizedDescription.includes('noivado') ||
      normalizedDescription.includes('cerimonia')
    ) {
      return faHeart;
    }
    if (
      normalizedDescription.includes('saude') ||
      normalizedDescription.includes('medicina') ||
      normalizedDescription.includes('vacina') ||
      normalizedDescription.includes('tratamento')
    ) {
      return faHeartbeat;
    }
    if (
      normalizedDescription.includes('tecnologia') ||
      normalizedDescription.includes('gadgets') ||
      normalizedDescription.includes('eletronicos') ||
      normalizedDescription.includes('computador') ||
      normalizedDescription.includes('smartphone')
    ) {
      return faMobileAlt;
    }
    if (
      normalizedDescription.includes('negocio') ||
      normalizedDescription.includes('empresa') ||
      normalizedDescription.includes('startup') ||
      normalizedDescription.includes('empreendimento')
    ) {
      return faBriefcase;
    }
    if (
      normalizedDescription.includes('sustentabilidade') ||
      normalizedDescription.includes('meio ambiente') ||
      normalizedDescription.includes('ecologia')
    ) {
      return faSeedling;
    }
    if (
      normalizedDescription.includes('mobiliario') ||
      normalizedDescription.includes('moveis') ||
      normalizedDescription.includes('decoracao')
    ) {
      return faCouch;
    }
    if (
      normalizedDescription.includes('academia') ||
      normalizedDescription.includes('musculacao') ||
      normalizedDescription.includes('exercicio') ||
      normalizedDescription.includes('fitness')
    ) {
      return faDumbbell;
    }
    if (
      normalizedDescription.includes('carreira') ||
      normalizedDescription.includes('profissao') ||
      normalizedDescription.includes('trabalho')
    ) {
      return faChartLine;
    }
    if (
      normalizedDescription.includes('eletronicos') ||
      normalizedDescription.includes('tecnologia') ||
      normalizedDescription.includes('computador')
    ) {
      return faMobileAlt;
    }
    if (
      normalizedDescription.includes('intercambio') ||
      normalizedDescription.includes('estudo no exterior') ||
      normalizedDescription.includes('viagem')
    ) {
      return faPlane;
    }
    if (
      normalizedDescription.includes('festa') ||
      normalizedDescription.includes('celebracao') ||
      normalizedDescription.includes('evento') ||
      normalizedDescription.includes('aniversario')
    ) {
      return faGift;
    }

    return faQuestionCircle;
  };

  const calcularPorcentagem = (saldoAtual, valorMeta) => {
    if (valorMeta > 0 && saldoAtual > 0) {
      return Math.min((saldoAtual / valorMeta) * 100, 100); 
    }
    return 0;
  };

  const calcularValorMensal = (valorMeta, dataInicio, prazo) => {
    const dataInicioDate = new Date(dataInicio);
    const prazoDate = new Date(prazo);
    const diffTime = Math.abs(prazoDate - dataInicioDate);
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30)); 
    
    if (diffMonths > 0) {
      return (valorMeta / diffMonths).toFixed(2);
    }
    return 0;
  };

  const formatarData = (data) => {
    if (!data) return '';
    const dataObj = new Date(data);
    dataObj.setMinutes(dataObj.getMinutes() + dataObj.getTimezoneOffset());
    const ano = dataObj.getFullYear();
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const dia = String(dataObj.getDate()).padStart(2, '0');
    return `${ano}-${mes}-${dia}`; 
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setSelectedMeta(null);
    }, 300); 
  };

  const handleEditClick = (meta) => {
    setSelectedMeta(meta);
    setIsModalOpen(true);
  };

  const formatarValor = (valor) => {
    if (!valor) return '';
    
    let val = parseFloat(valor).toFixed(2);
    val = val.replace('.', ','); 
    val = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); 
    return val;
  };

  const limparFormatacaoValor = (valorFormatado) => {
    if (!valorFormatado) return 0;
  
    // Remove pontos e substitui a vírgula por ponto para converter em um número decimal
    return parseFloat(valorFormatado.replace(/\./g, '').replace(',', '.'));
  };
  
  

// Função para lidar com as mudanças no campo de valor
const handleValorChange = (e) => {
    let rawValue = e.target.value;

    // Remove qualquer caractere que não seja número ou vírgula
    rawValue = rawValue.replace(/[^0-9,]/g, '');

    // Garante que apenas uma vírgula seja permitida
    const partes = rawValue.split(',');
    if (partes.length > 2) {
        rawValue = partes[0] + ',' + partes[1]; // Se houver mais de uma vírgula, mantém só a primeira
    }

    setSelectedMeta({ ...selectedMeta, valor: rawValue }); // Armazena o valor sem formatação monetária
};


const handleSaveMeta = async () => {
    const userId = localStorage.getItem('userId');
  
    if (selectedMeta) {
      // Remove a formatação e converte o valor para número
      const valorLimpo = limparFormatacaoValor(selectedMeta.valor); // Remover formatação (vírgula e ponto)
  
      const { id, descricao, prazo } = selectedMeta;
      try {
        const response = await fetch(`/api/users/${userId}/metas/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            valor: valorLimpo, // Envia o valor como número
            descricao,
            prazo
          })
        });
  
        if (response.ok) {
          const updatedMetas = metas.map((meta) =>
            meta.id === id ? { ...selectedMeta, valor: valorLimpo } : meta
          );
          setMetas(updatedMetas);
          toast.success('Meta editada com sucesso!');
          closeModal();
        } else {
          const data = await response.json();
          toast.error(data.message || 'Erro ao salvar a meta.');
        }
      } catch (error) {
        console.error('Erro ao salvar a meta:', error);
        toast.error('Erro ao salvar a meta.');
      }
    }
  };
  

  const handleDeleteMeta = async () => {
    const userId = localStorage.getItem('userId');
    if (selectedMeta) {
      const { id } = selectedMeta;
      const response = await fetch(`/api/users/${userId}/metas/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedMetas = metas.filter((meta) => meta.id !== id);
        setMetas(updatedMetas);
        toast.success('Meta excluída com sucesso!');
        closeModal();
      } else {
        toast.error('Erro ao excluir a meta.');
      }
    }
  };

  return (
    <div className="metas-container">
      <ToastContainer /> 
      
      <h2>Minhas Metas Financeiras</h2>
      {loading ? (
        <p>Carregando metas...</p>
      ) : (
        <table className="metas-table">
        <thead>
            <tr>
            <th>Descrição</th>
            <th>Carteira</th>
            <th>Valor</th>
            <th>Prazo</th>
            <th>Ações</th>
            </tr>
        </thead>
        <tbody>
            {metas.length === 0 ? (
            <tr>
                <td colSpan="5">Nenhuma meta cadastrada</td>
            </tr>
            ) : (
            metas.map((meta, index) => (
                <tr key={index}>
                <td>{meta.descricao}</td>
                <td>{meta.carteira || 'Sem categoria'}</td>
                <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: meta.moeda }).format(meta.valor)}</td>
                <td>{new Date(meta.prazo).toLocaleDateString()}</td>
                <td>
                    <FontAwesomeIcon icon={faEdit} onClick={() => handleEditClick(meta)} style={{ cursor: 'pointer' }} />
                </td>
                </tr>
            ))
            )}
        </tbody>
        </table>
      )}

      <h3>Mural dos Sonhos</h3>
      <div className="mural-dos-sonhos">
        {metas.length === 0 ? (
          <p>Nenhuma meta no mural dos sonhos</p>
        ) : (
          metas.map((meta, index) => {
            const porcentagem = calcularPorcentagem(meta.saldo_atual, meta.valor);
            const valorMensal = calcularValorMensal(meta.valor, meta.data_inicio, meta.prazo);

            return (
              <div className="mural-item" key={index}>
                <FontAwesomeIcon icon={getIconByDescription(meta.descricao)} className="mural-icon" />
                <div className="mural-info">
                  <h4>{meta.descricao}</h4>
                  <p>Preço: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: meta.moeda }).format(meta.valor)}</p>
                  <p>{meta.prazo ? `Prazo: ${new Date(meta.prazo).toLocaleDateString()}` : 'Prazo não definido'}</p>
                  <p className="valor-mensal">
                    <span className="texto">Valor mensal a ser guardado:</span>
                    <span className="valor">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: meta.moeda }).format(valorMensal)}</span>
                  </p>
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${porcentagem}%` }}>
                      {porcentagem.toFixed(2)}%
                    </div>
                  </div>
                  {porcentagem >= 100 ? <p>Meta atingida!</p> : <p>Meta não atingida</p>}
                </div>
              </div>
            );
          })
        )}
      </div>

      {isModalOpen && (
  <div className={`meta-modal ${isModalOpen ? 'show' : ''}`}>
    <div className="modal-overlay" onClick={closeModal}></div>
    <div className="meta-modal-content">
      <button className="close-btn" onClick={closeModal}>&times;</button>
      
      <FontAwesomeIcon icon={faTrashAlt} className="delete-icon" onClick={handleDeleteMeta} /> 
      
      <h3>Editar Meta</h3>
      <div className="form-group">
        <label>Descrição</label>
        <input
          type="text"
          value={selectedMeta?.descricao || ''} 
          onChange={(e) => setSelectedMeta({ ...selectedMeta, descricao: e.target.value })}
        />
      </div>
      <div className="form-group">
  <label>Valor</label>
  <input
    type="text"
    value={selectedMeta?.valor || ''} 
    onChange={handleValorChange} 
  />
</div>


      <div className="form-group">
        <label>Prazo</label>
        <input
          type="date"
          value={selectedMeta?.prazo ? formatarData(selectedMeta.prazo) : ''} 
          onChange={(e) => setSelectedMeta({ ...selectedMeta, prazo: e.target.value })}
        />
      </div>
      <div className="form-group">
        <button onClick={handleSaveMeta} className="btn btn-primary">Salvar</button>
        <button onClick={closeModal} className="btn btn-secondary">Cancelar</button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Metas;
