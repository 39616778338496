import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { differenceInDays } from 'date-fns';

// Componente de Rota Protegida por Nível de Usuário e Data de Cadastro
const ProtectedLevelRoute = ({ userId, children }) => {
    const [nivelUsuario, setNivelUsuario] = useState(null);
    const [dataCadastro, setDataCadastro] = useState(null);
    const [liberado, setLiberado] = useState(false); // Controla o acesso à página
    const [carregando, setCarregando] = useState(true); // Para evitar redirecionar antes da verificação

    // Verifica se a data de cadastro está dentro dos últimos 7 dias
    const isCadastroWithinEightDays = (dataCadastro) => {
        if (!dataCadastro) return false;

        const dataAtual = new Date();
        const [year, month, day] = dataCadastro.split('-');
        const dataCadastroDate = new Date(year, month - 1, day);
        return differenceInDays(dataAtual, dataCadastroDate) <= 7;
    };

    // Função para buscar o nível do usuário e a data de cadastro
    const fetchUserLevel = async () => {
        try {
            const response = await fetch(`/api/users_nivel/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setNivelUsuario(data.nivel);
                setDataCadastro(data.data_cadastro);

                // Verifica se o nível do usuário ou a data de cadastro atendem aos critérios de liberação
                if (data.nivel > 1 || isCadastroWithinEightDays(data.data_cadastro)) {
                    setLiberado(true); // Libera a rota
                } else {
                    setLiberado(false); // Bloqueia a rota
                }
            } else {
                console.error('Erro ao buscar o nível do usuário:', data.message);
                setLiberado(false); // Se houver erro, bloqueia a rota
            }
        } catch (error) {
            console.error('Erro ao buscar o nível do usuário e a data de cadastro:', error);
            setLiberado(false);
        } finally {
            setCarregando(false); // Finaliza o carregamento
        }
    };

    useEffect(() => {
        if (userId) {
            fetchUserLevel();
        }
    }, [userId]);

    // Se ainda está carregando os dados, evita o redirecionamento prematuro
    if (carregando) {
        return <div>Carregando...</div>;
    }

    // Se o usuário não tem permissão, redireciona para a página de planos
    if (!liberado) {
        return <Navigate to="/planos" />;
    }

    // Se o usuário tem permissão, renderiza o conteúdo da rota
    return children;
};

export default ProtectedLevelRoute;
