import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import html2pdf from 'html2pdf.js';

// Registrar os componentes do ChartJS
ChartJS.register(ArcElement, Tooltip, Legend);

const RelatorioMetas = ({ userId }) => {
    const [dadosMetas, setDadosMetas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(null);

    useEffect(() => {
        buscarMetas();
    }, [userId]);

    const buscarMetas = async () => {
        setLoading(true);
        setErro(null);

        try {
            const response = await axios.get(`/api/users/${userId}/relatorios/metas`);
            setDadosMetas(response.data.metas);
        } catch (error) {
            console.error('Erro ao buscar metas financeiras:', error);
            setErro('Erro ao carregar o relatório.');
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (value, currency) => {
        // Define a moeda padrão como 'BRL' se a moeda for null ou undefined
        const currencyCode = currency || 'BRL';
        
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: currencyCode,
        }).format(value);
    };
    

    const formatarDadosParaGrafico = () => {
        const labels = dadosMetas.map(meta => meta.descricao); // Usando o campo 'nome' para os labels
        const valores = dadosMetas.map(meta => meta.valor);

        return {
            labels,
            datasets: [
                {
                    label: 'Metas Financeiras',
                    data: valores,
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.6)',
                        'rgba(255, 99, 132, 0.6)',
                        'rgba(255, 206, 86, 0.6)',
                        'rgba(153, 102, 255, 0.6)'
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(153, 102, 255, 1)'
                    ],
                    borderWidth: 1,
                },
            ],
        };
    };

    const renderizarGrafico = () => {
        const dadosGrafico = formatarDadosParaGrafico();
        if (!dadosGrafico) return <p>Nenhum dado disponível para o gráfico.</p>;

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div id="grafico-pie" style={{ width: '60%', height: '60%' }}>
                    <Pie data={dadosGrafico} />
                </div>
            </div>
        );
    };

    const handleImprimir = () => {
        document.body.classList.add('esconder-icones'); // Adiciona a classe para esconder os ícones
        const printWindow = window.open('', '_blank');

        // Capturar o gráfico como uma imagem
        const canvas = document.querySelector('#grafico-pie canvas');
        const imageData = canvas.toDataURL('image/png'); // Converter o canvas para uma imagem PNG

        printWindow.document.write(`
            <html>
                <head>
                    <title>Relatório de Metas Financeiras</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h2 { text-align: center; margin-bottom: 20px; }
                        table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                        th, td { border: 1px solid black; padding: 8px; text-align: center; }
                        img { display: block; margin: 0 auto 30px auto; width: 400px; height: 400px; }
                    </style>
                </head>
                <body>
                    <h2>Relatório de Metas Financeiras</h2>
                    <img src="${imageData}" alt="Gráfico do relatório" />
                    ${document.querySelector('.relatorio-detalhado').innerHTML}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        document.body.classList.remove('esconder-icones'); // Remove a classe após a impressão
    };

    const handleGerarPDF = () => {
        document.body.classList.add('esconder-icones'); // Adiciona a classe para esconder os ícones
        const element = document.querySelector('.relatorio-detalhado');
        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5],
            filename: `Relatorio_Metas_Financeiras.pdf`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 3 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().from(element).set(opt).save().then(() => {
            document.body.classList.remove('esconder-icones'); // Remove a classe após gerar o PDF
        });
    };

    return (
        <div className="relatorio-detalhado">
            <h2>Metas Financeiras</h2>
            {erro && <p style={{ color: 'red' }}>{erro}</p>}
            {loading ? (
                <p>Carregando...</p>
            ) : (
                <div>
                    <div className="icone-acoes-relatorio">
                        <button onClick={handleImprimir}>
                            <FaPrint size={20} title="Imprimir" />
                        </button>
                        <button onClick={handleGerarPDF}>
                            <FaFilePdf size={20} title="Gerar PDF" />
                        </button>
                    </div>

                    {renderizarGrafico()}

                    <table>
                        <thead>
                            <tr>
                                <th>Meta</th>
                                <th>Objetivo</th>
                                <th>Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dadosMetas.length > 0 ? (
                                dadosMetas.map((meta, index) => (
                                    <tr key={index}>
                                        <td>{meta.descricao}</td>
                                        <td>{formatCurrency(meta.valor, meta.moeda_carteira)}</td>
                                        <td>{formatCurrency(meta.saldo_atual, meta.moeda_carteira)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">Nenhuma meta disponível</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default RelatorioMetas;
