import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import './css/ReceitasDespesasBarChart.css';
import { differenceInDays } from 'date-fns';

const ReceitasDespesasBarChart = ({ userId }) => {
    const [dadosAno, setDadosAno] = useState([]);
    const chartRef = useRef(null);
    const [nivelUsuario, setNivelUsuario] = useState(1); 
    const [dataCadastro, setDataCadastro] = useState(null);
    const [liberado, setLiberado] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) {
            console.error('User ID não fornecido');
            return;
        }

        const fetchDadosAno = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/receitasDespesasPorAno`);
                const data = await response.json();
                if (response.ok) {
                    setDadosAno(data.receitasDespesasPorAno || []);
                } else {
                    console.error('Erro ao buscar as receitas e despesas por ano:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar as receitas e despesas por ano:', error);
            }
        };

        const fetchUserLevel = async () => {
            try {
                const response = await fetch(`/api/users_nivel/${userId}`);
                const data = await response.json();
                if (response.ok) {
                    setNivelUsuario(data.nivel);
                    setDataCadastro(data.data_cadastro);

                    if (data.nivel > 1 || isCadastroWithinEightDays(data.data_cadastro)) {
                        setLiberado(true);
                    } else {
                        setLiberado(false);
                    }
                } else {
                    console.error('Erro ao buscar o nível do usuário e a data de cadastro:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar o nível do usuário e a data de cadastro:', error);
            }
        };

        fetchUserLevel();
        fetchDadosAno();
    }, [userId]);

    useEffect(() => {
        const updateLegendColors = () => {
            const chartInstance = chartRef.current;
            if (chartInstance) {
                const isDarkMode = document.body.classList.contains('dark-mode');
                const fontColor = isDarkMode ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color');

                chartInstance.options.scales.x.ticks.color = fontColor;
                chartInstance.options.scales.y.ticks.color = fontColor;
                chartInstance.options.plugins.legend.labels.color = fontColor;
                chartInstance.update();
            }
        };

        const observer = new MutationObserver(() => {
            updateLegendColors();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const isCadastroWithinEightDays = (dataCadastro) => {
        if (!dataCadastro) {
            return false;
        }

        const dataAtual = new Date();
        const [year, month, day] = dataCadastro.split('-');
        const dataCadastroDate = new Date(year, month - 1, day);

        return differenceInDays(dataAtual, dataCadastroDate) <= 7;
    };

    const montarDadosGrafico = () => {
        return {
            labels: dadosAno.map(dado => dado.ano),
            datasets: [
                {
                    label: 'Receitas',
                    data: dadosAno.map(dado => dado.totalReceita),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)', 
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Despesas',
                    data: dadosAno.map(dado => dado.totalDespesa),
                    backgroundColor: 'rgba(255, 99, 132, 0.6)', 
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const handleNavigateToDetails = () => {
        if (!liberado) {
            navigate('/planos');
        } else {
            navigate('/lancamentos');
        }
    };

    const options = {
        scales: {
            x: {
                ticks: {
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
            y: {
                ticks: {
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        plugins: {
            legend: {
                labels: {
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="receitasDespesasBarChartCard">
            <div className="card-header">
                Receitas e Despesas por Ano
                <button onClick={handleNavigateToDetails} className="btn-ver-detalhes">
                    Ver Detalhes
                </button>
            </div>
            <div className="card-content">
                <div className={`${!liberado ? 'blurred-content' : ''}`}>
                    <Bar ref={chartRef} data={montarDadosGrafico()} options={options} />
                </div>
                {!liberado && (
                    <div className="message-overlay">
                        <p>Para ver este gráfico claramente, assine um plano.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReceitasDespesasBarChart;
