import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Carteiras.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Carteiras = () => {
    const [carteirasRegulares, setCarteirasRegulares] = useState([]);
    const [carteirasMetas, setCarteirasMetas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [editedItem, setEditedItem] = useState({
        nome: '',
        saldo_atual: '', // Para carteiras
        tipo: '', // Para carteiras
        ativa: '',
        moeda: '' // Para ambos
    });
    
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        fetchCarteirasRegulares();
        fetchCarteirasMetas();
    }, []);

    const fetchCarteirasRegulares = async () => {
        try {
            const response = await fetch(`/api/users/${userId}/carteiras/regulares`);
            if (!response.ok) {
                throw new Error(`Erro na API de carteiras regulares: ${response.statusText}`);
            }
            const data = await response.json();
            setCarteirasRegulares(data.carteirasRegulares || []);
        } catch (error) {
            console.error('Erro ao buscar carteiras regulares:', error);
        }
    };

    const fetchCarteirasMetas = async () => {
        try {
            const response = await fetch(`/api/users/${userId}/carteiras/metas`);
            if (!response.ok) {
                throw new Error(`Erro na API de carteiras metas: ${response.statusText}`);
            }
            const data = await response.json();
            setCarteirasMetas(data.carteiras || []);
        } catch (error) {
            console.error('Erro ao buscar carteiras de metas:', error);
        }
    };

    // Função para formatar o valor monetário corretamente para exibição
    const formatarValorExibicao = (valor) => {
        if (typeof valor === 'number') {
            return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
        return 'N/A';
    };

    // Função para formatar o valor digitado pelo usuário no modal
    const formatarValorInput = (valor) => {
        if (!valor) return ''; 
        const valorStr = valor.toString().replace(/[^\d]/g, '');
        const num = (valorStr / 100).toFixed(2).replace('.', ',');
        return num;
    };

    const handleValueChange = (e) => {
        const valorFormatado = formatarValorInput(e.target.value);
        setEditedItem({ ...editedItem, saldo_atual: valorFormatado });
    };

    const getIconeCarteira = (tipo) => {
        if (!tipo) return <i className="fas fa-wallet"></i>;
        switch (tipo.toLowerCase()) {
            case 'conta corrente':
                return <i className="fas fa-university"></i>;
            case 'poupança':
                return <i className="fas fa-piggy-bank"></i>;
            case 'investimentos':
                return <i className="fas fa-chart-line"></i>;
            case 'metas':
                return <i className="fas fa-bullseye"></i>;
            default:
                return <i className="fas fa-wallet"></i>;
        }
    };

    const handleEditarCarteira = (item, tipo) => {
        if (tipo === 'carteira' && item.id) {
            // Para carteiras (regulares ou de metas)
            setItemToEdit({ ...item, tipo: 'carteira' });
            setEditedItem({
                nome: item.nome,
                saldo_atual: item.saldo_atual || '',
                tipo: item.tipo || 'Metas', // Garante que o tipo de carteira de metas seja 'Metas'
                ativa: item.ativa !== undefined ? item.ativa : '1', // Garante que o valor de ativa seja mantido
                moeda: item.moeda || ''
            });
            setShowEditModal(true); // Abre o modal de edição
        } else {
            console.error('Erro: ID do item não encontrado.');
        }
    };
    

    const handleSaveEdit = async () => {
        const { id, tipo } = itemToEdit;
        let apiUrl;
        let updatedFields;
    
        if (tipo === 'carteira') {
            updatedFields = {
                nome: editedItem.nome,
                saldo_atual: converterValorParaNumero(editedItem.saldo_atual),
                ativa: editedItem.ativa, // Certifique-se de que o valor `ativa` seja enviado corretamente
                moeda: editedItem.moeda,
                tipo: editedItem.tipo // Certifique-se de que o tipo esteja sendo enviado corretamente
            };
            apiUrl = `/api/users/${userId}/carteiras/${id}`;
        }
    
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFields),
            });
    
            if (response.ok) {
                toast.success('Carteira editada com sucesso!');
                fetchCarteirasRegulares();
                fetchCarteirasMetas();
                setShowEditModal(false); // Fecha o modal de edição
            } else {
                throw new Error(`Erro ao editar o item: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Erro ao editar o item:', error);
            toast.error('Erro ao editar a carteira. Tente novamente.');
        }
    };
    

    const handleExcluirItem = (item, tipo) => {
        if (tipo === 'carteira' && item.id) {
            setItemToDelete({ id: item.id, tipo: 'carteira' });
        } else {
            console.error('Erro: ID do item não encontrado.');
        }
        setShowModal(true); // Abre o modal de confirmação
    };

    const confirmDelete = async () => {
        const { id, tipo } = itemToDelete;
    
        if (!id) {
            console.error('Erro: Nenhum ID encontrado para o item a ser excluído.');
            return;
        }
    
        const apiUrl = `/api/users/${userId}/carteiras/${id}`;
    
        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
            });
    
            if (response.ok) {
                toast.success('Carteira excluída com sucesso!');
                
                // Atualiza o estado removendo a carteira excluída da lista
                if (tipo === 'carteira') {
                    setCarteirasRegulares((prevCarteiras) =>
                        prevCarteiras.filter((carteira) => carteira.id !== id)
                    );
                } else if (tipo === 'metas') {
                    setCarteirasMetas((prevCarteiras) =>
                        prevCarteiras.filter((carteira) => carteira.id !== id)
                    );
                }
    
                setShowModal(false); // Fecha o modal de confirmação
            } else {
                throw new Error(`Erro ao excluir o item: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Erro ao excluir o item:', error);
            toast.error('Erro ao excluir a carteira. Tente novamente.');
        }
    };
    

    const handleCloseModal = () => {
        setShowEditModal(false);
    };
    
    const handleCloseConfirmationModal = () => {
        setShowModal(false);
    };

    // Converte o valor do input (com vírgula) para um número decimal antes de salvar
    const converterValorParaNumero = (valor) => {
        if (!valor) return 0;

        // Verifica se o valor já é um número e retorna como está
        if (typeof valor === 'number') {
            return valor;
        }

        // Garante que o valor seja tratado como uma string antes de usar replace
        const valorStr = String(valor).replace(/\./g, '').replace(',', '.');

        return parseFloat(valorStr);
    };


    return (
        <div className="container-carteiras">
            <h2>Minhas Carteiras</h2>
            <div className="lista-carteiras">
                {/* Carteiras Regulares */}
                <h3>Carteiras Regulares</h3>
                <div className="container-item">
                    {carteirasRegulares.length > 0 ? (
                        carteirasRegulares.map((carteira, index) => (
                            <div key={`carteira-regular-${carteira.id}-${index}`} className={`item-carteira ${carteira.tipo ? carteira.tipo.toLowerCase().replace(' ', '-') : ''}`}>
                                <div className="icone-carteira">
                                    {getIconeCarteira(carteira.tipo)}
                                </div>
                                {carteira.ativa === 0 && (
                                    <div className="desativado-marca">
                                        <i className="fas fa-ban"></i> Desativado
                                    </div>
                                )}
                                <div className="conteudo-item">
                                    <h4>{carteira.nome}</h4>
                                    <p className={`valor-destaque ${carteira.saldo_atual < 0 ? 'saldo-negativo' : ''} ${carteira.ativa === 0 ? 'riscado' : ''}`}>
                                        Saldo: {formatarValorExibicao(carteira.saldo_atual)}
                                    </p>
                                    <p className="tipo-carteira">{carteira.tipo}</p>
                                </div>
                                <div className="acao-icones">
                                    <i className="fas fa-edit editar" title="Editar Carteira" onClick={() => handleEditarCarteira(carteira, 'carteira')}></i>
                                    <i className="fas fa-trash excluir" title="Excluir Carteira" onClick={() => handleExcluirItem(carteira, 'carteira')}></i>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nenhuma carteira regular encontrada.</p>
                    )}
                </div>

                {/* Modal de Edição */}
                {showEditModal && itemToEdit && (
                    <div className={`carteira-modal ${showEditModal ? 'show' : ''}`} onClick={handleCloseModal}>
                        <div className="carteira-modal-content" onClick={(e) => e.stopPropagation()}>
                            <button className="close-btn" onClick={() => setShowEditModal(false)}>×</button>
                            <h2>Editar Carteira</h2>
                            <label>
                                Nome:
                                <input
                                    type="text"
                                    value={editedItem.nome || ''}  // Garante que o valor não seja undefined
                                    onChange={(e) => setEditedItem({ ...editedItem, nome: e.target.value })}
                                />
                            </label>
                            <label>
                                Tipo de Carteira:
                                <select
                                    value={editedItem.tipo || 'Metas'}  // Aqui garantimos que o valor não seja null ou undefined
                                    onChange={(e) => setEditedItem({ ...editedItem, tipo: e.target.value })}
                                >
                                    <option value="Conta Corrente">Conta Corrente</option>
                                    <option value="Poupança">Poupança</option>
                                    <option value="Investimentos">Investimentos</option>
                                    <option value="Metas">Metas</option>
                                </select>
                            </label>

                            <label>
                                Saldo Atual:
                                <input
                                    type="text"
                                    value={editedItem.saldo_atual}
                                    onChange={handleValueChange}
                                />
                            </label>
                            <label>
                                Ativa:
                                <select
                                    value={editedItem.ativa}
                                    onChange={(e) => setEditedItem({ ...editedItem, ativa: e.target.value })}
                                >
                                    <option value="1">Ativa</option>
                                    <option value="0">Desativada</option>
                                </select>
                            </label>
                            <div className="btn-container">
                                <button className="btn btn-primary" onClick={handleSaveEdit}>Salvar</button>
                                <button className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal de Confirmação */}
                <div className={`carteira-modal ${showModal ? 'show' : ''}`} onClick={handleCloseConfirmationModal}>
                    <div className="carteira-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={() => setShowModal(false)}>×</button>
                        <h2>Confirmar Exclusão</h2>
                        <p>Tem certeza que deseja excluir este item?</p>
                        <div className="btn-container">
                            <button className="btn btn-primary" onClick={confirmDelete}>Sim</button>
                            <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancelar</button>
                        </div>
                    </div>
                </div>

                {/* Carteiras de Metas */}
                <h3>Carteiras de Metas</h3>
                <div className="container-item">
                    {carteirasMetas.length > 0 ? (
                        carteirasMetas.map((carteira, index) => (
                            <div key={`carteira-metas-${carteira.id}-${index}`} className={`item-carteira metas`}>
                                <div className="icone-carteira">
                                    {getIconeCarteira(carteira.tipo)}
                                </div>
                                    {carteira.ativa === 0 && (
                                        <div className="desativado-marca">
                                            <i className="fas fa-ban"></i> Desativado
                                        </div>
                                    )}

                                <div className="conteudo-item">
                                    <h4>{carteira.nome}</h4>
                                    <p className={`valor-destaque ${carteira.saldo_atual < 0 ? 'saldo-negativo' : ''} ${carteira.ativa === 0 ? 'riscado' : ''}`}>
                                        Saldo: {formatarValorExibicao(carteira.saldo_atual)}
                                    </p>
                                    <p className="tipo-carteira">{carteira.tipo || 'Metas'}</p> {/* Garante que o tipo não será null */}
                                </div>
                                <div className="acao-icones">
                                    <i className="fas fa-edit editar" title="Editar Carteira" onClick={() => handleEditarCarteira(carteira, 'carteira')}></i>
                                    <i className="fas fa-trash excluir" title="Excluir Carteira" onClick={() => handleExcluirItem(carteira, 'carteira')}></i>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nenhuma carteira de metas encontrada.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Carteiras;
