import React, { useState, useEffect } from 'react';
import '../../assets/styles/main.css';
import './config.css'; 
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Config = () => {
  const [settings, setSettings] = useState({
    sumario_mensal: 0,
    status_metas: 0,
    rendimentos_investimentos: 0,
    dicas_financeiras_mensais: 0,
    revisao_saldo: 0,
    alertas_orcamento: 0,
    alertas_saldo: 0,
    lembretes_pagamento: 0,
    alertas_transacao: 0,
    lembretes_metas: 0,
    zap_login_alerta: 0,
  });

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    
    const getNotificationSettings = async (userId) => {
      try {
        const response = await axios.get(`/api/user/notifications`, {
          params: { id: userId },
        });
        return response.data;
      } catch (error) {
        console.error('Erro ao buscar configurações de notificações:', error);
        return null;
      }
    };

    getNotificationSettings(userId).then((data) => {
      if (data) {
        setSettings(data);
      }
    });
  }, []);

  const handleToggle = (field) => {
    const newValue = settings[field] === 1 ? 0 : 1;
    setSettings({ ...settings, [field]: newValue });
    const userId = localStorage.getItem('userId');

    const updateNotificationSetting = async (userId, field, value) => {
      try {
        const response = await axios.post(`/api/user/notifications/update`, {
          userId,
          field,
          value,
        });
        if (response.data) {
          toast.success('Configuração atualizada com sucesso!');
        }
      } catch (error) {
        console.error('Erro ao atualizar configuração de notificação:', error);
        toast.error('Erro ao atualizar configuração de notificação.');
      }
    };

    updateNotificationSetting(userId, field, newValue);
  };

  return (
    <div className="config-container">
      <div className="config-box">
        <h2>Configurações de Notificações</h2>
        <section className="config-section">
          <h3>Configurações de Conta</h3>
          <div className="form-row">
            <div className="form-group-config">
              <label>Sumário Mensal</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.sumario_mensal === 1}
                    onChange={() => handleToggle('sumario_mensal')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Alerta de Login pelo WhatsApp</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.zap_login_alerta === 1}
                    onChange={() => handleToggle('zap_login_alerta')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </section>
        <section className="config-section">
          <h3>Configurações de Usuário</h3>
          <div className="form-row">
            <div className="form-group-config">
              <label>Status das Metas</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.status_metas === 1}
                    onChange={() => handleToggle('status_metas')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Rendimentos de Investimentos</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.rendimentos_investimentos === 1}
                    onChange={() => handleToggle('rendimentos_investimentos')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </section>
        <section className="config-section">
          <h3>Notificações</h3>
          <div className="form-row">
            <div className="form-group-config">
              <label>Dicas Financeiras Mensais</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.dicas_financeiras_mensais === 1}
                    onChange={() => handleToggle('dicas_financeiras_mensais')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Revisão de Saldo</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.revisao_saldo === 1}
                    onChange={() => handleToggle('revisao_saldo')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Alertas de Orçamento</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.alertas_orcamento === 1}
                    onChange={() => handleToggle('alertas_orcamento')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Alertas de Saldo</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.alertas_saldo === 1}
                    onChange={() => handleToggle('alertas_saldo')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Lembretes de Pagamento</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.lembretes_pagamento === 1}
                    onChange={() => handleToggle('lembretes_pagamento')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Alertas de Transação</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.alertas_transacao === 1}
                    onChange={() => handleToggle('alertas_transacao')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="form-group-config">
              <label>Lembretes de Metas pelo WhatsApp</label>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={settings.lembretes_metas === 1}
                    onChange={() => handleToggle('lembretes_metas')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Config;
