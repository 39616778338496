import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Certifique-se de que os estilos também estão importados
import ModalPagarFatura from './ModalPagarFatura'; // Importação do modal

import './CartoesCredito.css';

const CartoesCredito = () => {
    const [cartoes, setCartoes] = useState([]);
    const [carteiras, setCarteiras] = useState([]); // Para listar as carteiras
    const [showModal, setShowModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showModalPagar, setShowModalPagar] = useState(false); // Modal para pagar fatura
    const [cartaoSelecionado, setCartaoSelecionado] = useState(null); // Para armazenar o cartão a ser pago
    const [valorFatura, setValorFatura] = useState(0); // Valor da fatura a ser pago

    const [editedItem, setEditedItem] = useState({
        nome: '',
        limite_credito: '',
        saldo_disponivel: '',
        data_vencimento_fatura: '',
        data_virada_fatura: '',
        ativa: '',
        moeda: ''
    });
    
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        fetchCartoesCredito();
        fetchCarteiras(); // Carrega as carteiras para o modal de pagamento

        // Adiciona o evento para fechar o modal com "Esc"
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                if (showEditModal) setShowEditModal(false);
                if (showModal) setShowModal(false);
                if (showModalPagar) setShowModalPagar(false); // Fecha o modal de pagamento
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [showEditModal, showModal, showModalPagar]);

    const fetchCartoesCredito = async () => {
        try {
            const response = await fetch(`/api/users/${userId}/cartoes-lista`);
            if (!response.ok) {
                throw new Error(`Erro na API de cartões de crédito: ${response.statusText}`);
            }
            const data = await response.json();
    
            const cartoesComId = data.cartoes.filter(cartao => cartao.id_cartao);
            setCartoes(cartoesComId);
        } catch (error) {
            console.error('Erro ao buscar cartões de crédito:', error);
        }
    };

    const fetchCarteiras = async () => {
        try {
            const response = await fetch(`/api/users/${userId}/carteiras`);
            const data = await response.json();
            setCarteiras(data.carteiras);
        } catch (error) {
            console.error('Erro ao buscar carteiras:', error);
        }
    };

    const handleEditarCartao = (cartao) => {
        if (cartao.id_cartao) {
            setItemToEdit(cartao);
            setEditedItem({
                nome: cartao.nome,
                limite_credito: cartao.limite_credito || '',
                saldo_disponivel: cartao.saldo_disponivel || '',
                data_vencimento_fatura: cartao.data_vencimento_fatura || '',
                data_virada_fatura: cartao.data_virada_fatura || '',
                data_validade: cartao.data_validade || '',
                ativa: cartao.ativa !== undefined ? cartao.ativa : '1',
                moeda: cartao.moeda || ''
            });
            setShowEditModal(true);
        } else {
            console.error('Cartão sem ID:', cartao);
        }
    };

    const handleExcluirItem = (cartao) => {
        if (cartao.id_cartao) {
            setItemToDelete(cartao);
            setShowModal(true);
        } else {
            console.error('Cartão sem ID:', cartao);
        }
    };

    const handleCloseModal = () => {
        setShowEditModal(false);
    };

    const handleCloseConfirmationModal = () => {
        setShowModal(false);
    };

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('cartao-modal')) {
            setShowEditModal(false);
            setShowModal(false);
            setShowModalPagar(false);
        }
    };

    const handleSaveEdit = async () => {
        const { id_cartao } = itemToEdit;
        if (!id_cartao) {
            console.error('Erro: ID do cartão não encontrado.');
            return;
        }
    
        const updatedFields = {
            nome: editedItem.nome,
            limite_credito: editedItem.limite_credito,
            saldo_disponivel: editedItem.saldo_disponivel,
            data_validade: editedItem.data_validade,
            data_vencimento_fatura: editedItem.data_vencimento_fatura,
            data_virada_fatura: editedItem.data_virada_fatura,
            ativa: editedItem.ativa,
            moeda: editedItem.moeda,
        };
    
        const apiUrl = `/api/users/${userId}/cartoes-edita/${id_cartao}`;
    
        try {
            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFields),
            });
    
            if (response.ok) {
                toast.success('Cartão editado com sucesso.', { autoClose: 3000 });
                fetchCartoesCredito();
                setShowEditModal(false);
            } else {
                console.error('Erro ao editar o cartão.');
                toast.error('Erro ao editar o cartão. Tente novamente mais tarde.', { autoClose: 5000 });
            }
        } catch (error) {
            console.error('Erro ao editar o cartão:', error);
            toast.error('Erro ao editar o cartão. Tente novamente mais tarde.', { autoClose: 5000 });
        }
    };

    const confirmDelete = async () => {
        const { id_cartao } = itemToDelete;

        if (!id_cartao) {
            console.error('Erro: Nenhum ID encontrado para o item a ser excluído.');
            return;
        }

        const apiUrl = `/api/users/${userId}/cartoes-excluir/${id_cartao}`;

        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchCartoesCredito();
                setShowModal(false);
            } else {
                throw new Error(`Erro ao excluir o cartão: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Erro ao excluir o cartão:', error);
        }
    };

    // Função para abrir o modal de pagamento
    const handlePagarFatura = (cartao) => {
        // Calcula o valor da fatura (limite de crédito menos saldo disponível)
        const fatura = cartao.limite_credito - cartao.saldo_disponivel;
        setCartaoSelecionado(cartao);
        setValorFatura(fatura); // Passa o valor, incluindo negativos
        setShowModalPagar(true);
    };


    // Função de confirmação do pagamento
    const handleConfirmarPagamento = async (id_carteira, valorPagar) => {
        // Verifica se o valor a pagar é positivo e se a carteira foi selecionada (carteira só é necessária para valores positivos)
        if (parseFloat(valorPagar) > 0 && !id_carteira) {
            toast.error('Selecione uma carteira para o pagamento.');
            return;
        }

        // Certifique-se de que o valor a pagar é válido (positivo ou negativo)
        if (!valorPagar || isNaN(valorPagar)) {
            toast.error('Insira um valor válido para o pagamento.');
            return;
        }

        const apiUrl = `/api/users/${userId}/pagar-fatura`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id_cartao: cartaoSelecionado.id_cartao,
                    valor_pago: parseFloat(valorPagar), // Usando o valor do input
                    id_carteira: parseFloat(valorPagar) > 0 ? id_carteira : null, // Carteira é enviada apenas para valores positivos
                }),
            });

            if (response.ok) {
                toast.success('Fatura paga com sucesso.');
                fetchCartoesCredito(); // Atualiza os cartões após o pagamento
                setShowModalPagar(false); // Fecha o modal
            } else {
                const errorData = await response.json();
                toast.error(`Erro ao pagar a fatura: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Erro ao pagar a fatura:', error);
            toast.error('Erro ao pagar a fatura. Tente novamente mais tarde.');
        }
    };




    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Formato yyyy-MM-dd
    };

    const handleDayChange = (newDay) => {
        const [year, month] = editedItem.data_vencimento_fatura.split('-');
        const newDate = `${year}-${month}-${String(newDay).padStart(2, '0')}`;

        setEditedItem({
            ...editedItem,
            data_vencimento_fatura: newDate,
        });
    };

    const formatarValorExibicao = (valor) => {
        if (typeof valor === 'number') {
            return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
        return 'N/A';
    };

    return (
        <div className="container-cartoes">
            <h2>Meus Cartões de Crédito</h2>
            <div className="lista-cartoes">
                <div className="container-item">
                    {cartoes.length > 0 ? (
                        cartoes.map((cartao, index) => {
                            const valorFatura = cartao.limite_credito - cartao.saldo_disponivel;
                            const isPagamentoDesabilitado = valorFatura === 0; // Desabilita apenas se for exatamente 0

                            return (
                                <div key={`cartao-${cartao.id_cartao}-${index}`} className="item-cartao">
                                    <div className="icone-cartao">
                                        <i className="fas fa-credit-card"></i>
                                    </div>
                                    <div className="conteudo-item">
                                        <h4>{cartao.nome}</h4>
                                        <p className="limite-destaque">Limite: {formatarValorExibicao(cartao.limite_credito)}</p>
                                        <p className={`valor-destaque ${cartao.saldo_disponivel < 0 ? 'saldo-negativo' : ''}`}>
                                            Saldo Disponível: {formatarValorExibicao(cartao.saldo_disponivel)}
                                        </p>
                                        <p className="valor-fatura">
                                            Fatura: {formatarValorExibicao(valorFatura)}
                                        </p>
                                        <p>Vencimento da Fatura: {new Date(cartao.data_vencimento_fatura).getDate()}</p>
                                    </div>

                                    <div className="cartoes-container">
                                        <div className="acao-icones">
                                            <button
                                                className="btn-icone editar"
                                                title="Editar Cartão"
                                                onClick={() => handleEditarCartao(cartao)}
                                            >
                                                <i className="fas fa-edit"></i>
                                            </button>
                                            <button
                                                className="btn-icone excluir"
                                                title="Excluir Cartão"
                                                onClick={() => handleExcluirItem(cartao)}
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                            <button
                                                className={`btn-icone pagar ${isPagamentoDesabilitado ? 'disabled' : ''}`}
                                                title={isPagamentoDesabilitado ? "Nenhuma fatura para pagar" : "Pagar Fatura"}
                                                onClick={() => handlePagarFatura(cartao)}
                                                disabled={isPagamentoDesabilitado} // Desabilita o botão se a fatura for 0
                                            >
                                                <i className="fas fa-wallet"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p>Nenhum cartão de crédito encontrado.</p>
                    )}
                </div>
            </div>

            {/* Modal de Edição */}
            {showEditModal && itemToEdit && (
                <div className={`cartao-modal ${showEditModal ? 'show' : ''}`} onClick={handleClickOutside}>
                    <div className="cartao-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={() => setShowEditModal(false)}>×</button>
                        <h2>Editar Cartão</h2>
                        <label>
                            Nome:
                            <input
                                type="text"
                                value={editedItem.nome || ''}
                                onChange={(e) => setEditedItem({ ...editedItem, nome: e.target.value })}
                            />
                        </label>
                        <label>
                            Limite de Crédito:
                            <input
                                type="text"
                                value={editedItem.limite_credito || ''}
                                onChange={(e) => setEditedItem({ ...editedItem, limite_credito: e.target.value })}
                            />
                        </label>
                        <label>
                            Saldo Disponível:
                            <input
                                type="text"
                                value={editedItem.saldo_disponivel || ''}
                                onChange={(e) => setEditedItem({ ...editedItem, saldo_disponivel: e.target.value })}
                            />
                        </label>
                        <label>
                            Vencimento da Fatura:
                            <input
                                type="number"
                                value={parseInt(editedItem.data_vencimento_fatura.split('-')[2], 10)}
                                min="1" 
                                max="31" 
                                onChange={(e) => handleDayChange(e.target.value)}
                            />
                        </label>
                        <div className="btn-container">
                            <button className="btn btn-primary" onClick={handleSaveEdit}>Salvar</button>
                            <button className="btn btn-secondary" onClick={() => setShowEditModal(false)}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de Confirmação */}
            {showModal && itemToDelete && (
                <div className={`cartao-modal ${showModal ? 'show' : ''}`} onClick={handleClickOutside}>
                    <div className="cartao-modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="close-btn" onClick={() => setShowModal(false)}>×</button>
                        <h2>Confirmar Exclusão</h2>
                        <p>Tem certeza que deseja excluir este cartão?</p>
                        <div className="btn-container">
                            <button className="btn btn-primary" onClick={confirmDelete}>Sim</button>
                            <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal para Pagar a Fatura */}
            {showModalPagar && cartaoSelecionado && (
                <ModalPagarFatura
                    cartao={cartaoSelecionado}
                    carteiras={carteiras}
                    valorFatura={valorFatura} // Passa o valor da fatura calculado
                    show={showModalPagar}
                    handleClose={() => setShowModalPagar(false)}
                    handleConfirm={handleConfirmarPagamento}
                />
            )}
        </div>
    );
};

export default CartoesCredito;
