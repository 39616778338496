import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import html2pdf from 'html2pdf.js';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

// Registrar os componentes do ChartJS
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// Função para mapear o número do mês para o nome do mês
const getNomeMes = (numeroMes) => {
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return meses[numeroMes - 1];
};

// Função para formatar valores em moeda
const formatCurrency = (value, currency) => {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency,
    }).format(value);
};

const RelatorioResumoPeriodo = ({ userId }) => {
    const [dadosRelatorio, setDadosRelatorio] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [erro, setErro] = useState(null);

    useEffect(() => {
        if (startDate && endDate) {
            buscarRelatorio();
        }
    }, [userId, startDate, endDate]);

    const buscarRelatorio = async () => {
        setLoading(true);
        setErro(null);

        try {
            const response = await axios.get(`/api/users/${userId}/relatorios/resumo-periodo`, {
                params: { startDate, endDate }
            });
            setDadosRelatorio(response.data);
        } catch (error) {
            console.error('Erro ao buscar resumo por período:', error);
            setErro('Erro ao carregar o relatório.');
        } finally {
            setLoading(false);
        }
    };

    const formatarDadosParaGrafico = () => {
        const labels = dadosRelatorio.map(item => getNomeMes(item.mes));
        const receitas = dadosRelatorio.map(item => item.total_receitas || 0);
        const despesas = dadosRelatorio.map(item => item.total_despesas || 0);
        const saldo = dadosRelatorio.map(item => item.saldo || 0);

        return {
            labels,
            datasets: [
                {
                    label: 'Receitas',
                    data: receitas,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Despesas',
                    data: despesas,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Saldo',
                    data: saldo,
                    backgroundColor: 'rgba(255, 206, 86, 0.6)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const handleImprimir = () => {
        document.body.classList.add('esconder-icones'); // Adiciona a classe para esconder os ícones
        const printWindow = window.open('', '_blank');
    
        // Capturar o gráfico como uma imagem
        const canvas = document.querySelector('#grafico-bar canvas');
        const imageData = canvas.toDataURL('image/png'); // Converter o canvas para uma imagem PNG
    
        printWindow.document.write(`
            <html>
                <head>
                    <title>Relatório Resumo Financeiro</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h2 { text-align: center; margin-bottom: 20px; }
                        table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                        th, td { border: 1px solid black; padding: 8px; text-align: center; }
                        img { display: block; margin: 0 auto 30px auto; width: 400px; height: 400px; }
                    </style>
                </head>
                <body>
                    <h2>Relatório Resumo Financeiro</h2>
                    <img src="${imageData}" alt="Gráfico do relatório" />
                    ${document.querySelector('.relatorio-detalhado').innerHTML}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        document.body.classList.remove('esconder-icones'); // Remove a classe após a impressão
    };
    
    const handleGerarPDF = () => {
        document.body.classList.add('esconder-icones'); // Adiciona a classe para esconder os ícones
        const element = document.querySelector('.relatorio-detalhado');
        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5],  // Margens menores para o PDF
            filename: `Relatorio_Resumo_Financeiro.pdf`,
            image: { type: 'jpeg', quality: 1 },  // Qualidade da imagem em 100%
            html2canvas: { scale: 3 },  // Scale alto para melhorar a resolução
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }  // Tamanho de papel padrão (Letter)
        };
    
        html2pdf().from(element).set(opt).save().then(() => {
            document.body.classList.remove('esconder-icones'); // Remove a classe após gerar o PDF
        });
    };
    

    return (
        <div className="relatorio-detalhado">
            <h2>Resumo Financeiro por Período</h2>
            <div>
                <label>Início: <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} /></label>
                <label>Fim: <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} /></label>
            </div>
            {erro && <p style={{ color: 'red' }}>{erro}</p>}
            {loading ? (
                <p>Carregando...</p>
            ) : (
                <div>
                    <div className="icone-acoes-relatorio">
                        <button onClick={handleImprimir}>
                            <FaPrint size={20} title="Imprimir" />
                        </button>
                        <button onClick={handleGerarPDF}>
                            <FaFilePdf size={20} title="Gerar PDF" />
                        </button>
                    </div>
                    {dadosRelatorio.length > 0 && (
                        <>
                            <Bar id="grafico-bar" data={formatarDadosParaGrafico()} />
                            <table>
                                <thead>
                                    <tr>
                                        <th>Mês</th>
                                        <th>Receitas</th>
                                        <th>Despesas</th>
                                        <th>Saldo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dadosRelatorio.map((item, index) => (
                                        <tr key={index}>
                                            <td>{getNomeMes(item.mes)}</td>
                                            <td>{formatCurrency(item.total_receitas, 'BRL')}</td>
                                            <td>{formatCurrency(item.total_despesas, 'BRL')}</td>
                                            <td>{formatCurrency(item.saldo, 'BRL')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default RelatorioResumoPeriodo;
