import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import './MetaModal.css'; // CSS para o modal de metas

const MetaModal = ({ isOpen, onClose }) => {
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState('');
    const [moeda, setMoeda] = useState('BRL');
    const [prazo, setPrazo] = useState('');
    const [carteira, setCarteira] = useState('');
    const [carteiras, setCarteiras] = useState([]);
    const [novaCarteira, setNovaCarteira] = useState('');
    const [adicionandoCarteira, setAdicionandoCarteira] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [validation, setValidation] = useState({
        descricao: true,
        valor: true,
        prazo: true,
        carteira: true,
    });

    // Carregar carteiras do tipo 'Metas' quando o modal for aberto
    useEffect(() => {
        if (isOpen) {
            const userId = localStorage.getItem('userId');
            fetch(`/api/users/${userId}/carteiras/metas`)
                .then(res => res.json())
                .then(data => {
                    setCarteiras(data.carteiras || []);
                })
                .catch(err => console.error('Erro ao carregar carteiras do tipo Metas:', err));
        }
    }, [isOpen]);


    // Função para criar uma carteira do tipo 'Metas'
    const handleCarteiraKeyDown = (e) => {
        if (e.key === 'Enter' && novaCarteira) {
            e.preventDefault();
            const userId = localStorage.getItem('userId');
            const carteiraExistente = carteiras.find(c => c.nome.toLowerCase() === novaCarteira.toLowerCase());

            if (carteiraExistente) {
                toast.error('Já existe uma carteira com este nome.');
                return;
            }

            // Log para depuração
            console.log('Criando nova carteira do tipo Metas:', novaCarteira);

            // Fazendo o fetch para criar a carteira do tipo 'Metas'
            fetch(`/api/users/${userId}/carteirasmetas`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    nome: novaCarteira,
                    tipo: 'Metas',  // Tipo de carteira 'Metas'
                    saldo_inicial: 0,  // Definido explicitamente como 0
                    saldo_atual: 0,    // Definido explicitamente como 0
                    moeda: 'BRL',
                    ativa: 1
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (data.message === 'Carteira cadastrada com sucesso.') {
                    const novaCarteiraAdicionada = { 
                        id: data.id_carteira || Math.random().toString(36).substr(2, 9), 
                        nome: novaCarteira
                    };
                    setCarteiras([...carteiras, novaCarteiraAdicionada]); // Adiciona a nova carteira à lista
                    setCarteira(novaCarteiraAdicionada.id);  // Seleciona a nova carteira no select
                    setNovaCarteira(novaCarteiraAdicionada.nome);  // Atualiza o nome da carteira para exibir no select
                    setAdicionandoCarteira(false);
                } else {
                    toast.error('Erro ao adicionar carteira.');
                }
            })
            
            .catch(err => {
                console.error('Erro ao adicionar carteira:', err);
                toast.error('Erro ao adicionar carteira.');
            });
        } else if (e.key === 'Escape') {
            setNovaCarteira('');
            setAdicionandoCarteira(false);
        }
    };

    // Função para formatar o valor digitado no campo de valor
    const formatarValor = (valor) => {
        let val = valor.replace(/\D/g, '');  // Remove todos os caracteres que não são dígitos
        val = (parseFloat(val) / 100).toFixed(2);  // Divide por 100 para ajustar os centavos
        return val;
    };

    // Função que lida com mudanças no campo de valor
    const handleValorChange = (e) => {
        const valorFormatado = e.target.value.replace(/\./g, '').replace(',', '.');  // Remove pontos e converte vírgula para ponto
        setValor(valorFormatado);  // Atualiza o estado com o valor numérico correto
    };

    // Função para validar o formulário
    const validateForm = () => {
        const validationState = {
            descricao: !!descricao,
            valor: !!valor,
            prazo: !!prazo,
            carteira: !!carteira,
        };

        setValidation(validationState);
        return Object.values(validationState).every(Boolean);
    };

    

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validateForm();
    
        if (!isValid) {
            toast.error('Preencha todos os campos obrigatórios.');
            return;
        }
    
        const userId = localStorage.getItem('userId');
    
        // Faz o POST para criar a meta enviando o ID da carteira
        fetch(`/api/users/${userId}/metas`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                valor: valor.replace(',', '.'),
                descricao,
                moeda,
                prazo,
                carteira: carteira,  // Aqui estamos enviando o ID da carteira
            }),
        })
        .then(res => res.json())
        .then(data => {
            if (data.message === 'Meta cadastrada com sucesso.') {
                toast.success('Meta cadastrada com sucesso!', {
                    onClose: () => {
                        window.location.reload(); // Recarrega a página após o fechamento do toast
                    }
                });
                onClose();
            } else {
                toast.error('Erro ao cadastrar meta.');
            }
        })
        .catch(err => {
            toast.error('Erro ao cadastrar meta.');
        });

    };
    
    
    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            setIsClosing(false);
        }, 300);
    };

    return (
        <div className={`meta-modal ${isOpen && !isClosing ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleClose}>
            <div className="meta-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-btn" onClick={handleClose}>&times;</button>
                <h2>Cadastrar Nova Meta</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Descrição da Meta:</label>
                        <input 
                            type="text" 
                            value={descricao} 
                            onChange={(e) => setDescricao(e.target.value)} 
                            required 
                            className={!validation.descricao ? 'invalid' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label>Valor:</label>
                        <input 
                            type="text" 
                            value={valor} 
                            onChange={handleValorChange} 
                            required 
                            placeholder="0,00" 
                            className={!validation.valor ? 'invalid' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label>Moeda:</label>
                        <select value={moeda} onChange={(e) => setMoeda(e.target.value)}>
                            <option value="BRL">Real (BRL)</option>
                            <option value="USD">Dólar (USD)</option>
                            <option value="EUR">Euro (EUR)</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Prazo:</label>
                        <input 
                            type="date" 
                            value={prazo} 
                            onChange={(e) => setPrazo(e.target.value)} 
                            required 
                            className={!validation.prazo ? 'invalid' : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label>Carteira:</label>
                        <div className="select-with-add">
                            {adicionandoCarteira ? (
                                <input 
                                    type="text" 
                                    value={novaCarteira} 
                                    onChange={(e) => setNovaCarteira(e.target.value)} 
                                    placeholder="Digite nova carteira..." 
                                    className="input-add-carteira"
                                    onKeyDown={handleCarteiraKeyDown}
                                />
                            ) : (
                                <>
                                    <select 
                                        value={carteira} 
                                        onChange={(e) => setCarteira(e.target.value)} 
                                        className={!validation.carteira ? 'invalid' : ''}
                                    >
                                        {novaCarteira && (
                                            <option value={carteira}>{novaCarteira}</option> // Exibe apenas a nova carteira
                                        )}
                                    </select>



                                    <FontAwesomeIcon 
                                        icon={faPlus} 
                                        className="add-carteira-icon" 
                                        onClick={() => setAdicionandoCarteira(true)} 
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    <div className="form-group">
                        <button 
                            className="btn btn-primary" 
                            type="submit"
                        >
                            Salvar Meta
                        </button>
                        <button className="btn btn-secondary" type="button" onClick={handleClose}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MetaModal;
