import React, { useState, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FaCheck, FaTimes, FaEye, FaEyeSlash, FaStar, FaCrown, FaRocket } from 'react-icons/fa'; // Ícones de React
import '../../assets/styles/main.css'; // CSS global
import './profile.css'; // CSS específico da página
import md5 from 'md5';

const Profile = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    cpf: '',
    registrationDate: '',
    country: '',
    currency: '',
    language: '',
    planLevel: 1, // Nível do plano do usuário, padrão é 1 (Gratuito)
  });

  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    repeatNewPassword: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isPasswordFormValid, setIsPasswordFormValid] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    phone: false,
    cpf: false,
  });
  const [passwordErrors, setPasswordErrors] = useState({
    currentPassword: false,
    newPassword: false,
    repeatNewPassword: false,
    passwordMatch: false,
    passwordRequirements: {
      lengthRequirement: false,
      numberRequirement: false,
      specialCharRequirement: false,
    },
  });

  useEffect(() => {
    const userId = localStorage.getItem('userId'); // Obtém o ID do usuário do localStorage
    const userDetails = JSON.parse(localStorage.getItem('userDetails')); // Obtém os detalhes do usuário do localStorage

    if (userId && userDetails) {
      fetch(`/api/user?id=${userId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Erro ao buscar dados do usuário.');
          }
          return response.json();
        })
        .then((data) => {
          if (data.message) {
            toast.error(data.message);
          } else {
            setUser({
              ...data,
              phone: formatPhone(data.phone),
              cpf: formatCPF(data.cpf),
              planLevel: userDetails.niv_usuario || 1, // Usando o plano do localStorage
            });
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar dados do usuário:', error);
          toast.error('Erro ao buscar dados do usuário.');
        });
    } else {
      toast.error('Usuário não autenticado.');
    }
  }, []);

  const validateForm = useCallback(() => {
    const isEmailValid = validateEmail(user.email);
    const isPhoneValid = validatePhone(user.phone);
    const isCpfValid = validateCPF(user.cpf);
    const areFieldsFilled = user.firstName && user.lastName && user.email && user.phone && user.cpf;

    setErrors({
      email: !isEmailValid,
      phone: !isPhoneValid,
      cpf: !isCpfValid,
    });

    setIsFormValid(isEmailValid && isPhoneValid && isCpfValid && areFieldsFilled);
  }, [user]);

  const validatePasswordForm = useCallback(() => {
    const isCurrentPasswordFilled = passwords.currentPassword !== '';
    const isNewPasswordFilled = passwords.newPassword !== '';
    const isRepeatNewPasswordFilled = passwords.repeatNewPassword !== '';

    const passwordsMatch = passwords.newPassword === passwords.repeatNewPassword;
    const passwordMeetsRequirements = validatePassword(passwords.newPassword);

    setPasswordErrors((prevErrors) => ({
      ...prevErrors,
      currentPassword: !isCurrentPasswordFilled,
      newPassword: !isNewPasswordFilled,
      repeatNewPassword: !isRepeatNewPasswordFilled,
      passwordMatch: passwordsMatch,
      passwordRequirements: {
        lengthRequirement: passwords.newPassword.length >= 8,
        numberRequirement: /\d/.test(passwords.newPassword),
        specialCharRequirement: /[!@#$%^&*(),.?":{}|<>]/.test(passwords.newPassword),
      },
    }));

    setIsPasswordFormValid(
      isCurrentPasswordFilled &&
      isNewPasswordFilled &&
      isRepeatNewPasswordFilled &&
      passwordsMatch &&
      passwordMeetsRequirements
    );
  }, [passwords]);

  useEffect(() => {
    validateForm();
    validatePasswordForm();
  }, [user, passwords, validateForm, validatePasswordForm]);

  const handleSaveProfile = (e) => {
    e.preventDefault();

    const cleanedUser = {
      ...user,
      phone: user.phone.replace(/\D/g, ''), // Remove a formatação do telefone
      cpf: user.cpf.replace(/\D/g, ''), // Remove a formatação do CPF
    };

    const userId = localStorage.getItem('userId');
    fetch('/api/user/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: userId, ...cleanedUser }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erro ao atualizar os dados.');
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          toast.success(data.message);
        } else {
          toast.error('Erro ao atualizar os dados.');
        }
      })
      .catch((error) => {
        console.error('Erro ao atualizar os dados:', error);
        toast.error('Erro ao atualizar os dados.');
      });
  };

  const handleSavePassword = async (e) => {
    e.preventDefault();

    const userId = localStorage.getItem('userId');

    try {
      const response = await fetch('/api/user/check-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: userId,
          currentPassword: passwords.currentPassword,
        }),
      });

      if (!response.ok) {
        throw new Error('Erro ao verificar a senha.');
      }

      const data = await response.json();

      if (!data.passwordCorrect) {
        toast.error('Senha atual incorreta.');
        return;
      }

      // Se a senha atual estiver correta, atualizar para a nova senha
      const updateResponse = await fetch('/api/user/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: userId,
          newPassword: passwords.newPassword,
        }),
      });

      if (!updateResponse.ok) {
        throw new Error('Erro ao atualizar a senha.');
      }

      const updateData = await updateResponse.json();

      if (updateData.message) {
        toast.success('Senha atualizada com sucesso!');
        setPasswords({
          currentPassword: '',
          newPassword: '',
          repeatNewPassword: '',
        });
      } else {
        toast.error('Erro ao atualizar a senha.');
      }
    } catch (error) {
      console.error('Erro ao verificar a senha:', error);
      toast.error('Erro ao atualizar a senha.');
    }
  };

  const handleSavePreferences = (e) => {
    e.preventDefault();

    const userId = localStorage.getItem('userId');
    const cleanedUser = {
      ...user,
      phone: user.phone.replace(/\D/g, ''), // Remove a formatação do telefone
      cpf: user.cpf.replace(/\D/g, ''), // Remove a formatação do CPF
      country: e.target.country.value,
      currency: e.target.currency.value,
      language: e.target.language.value,
    };

    fetch('/api/user/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: userId, ...cleanedUser }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Erro ao atualizar as preferências.');
        }
        return response.json();
      })
      .then((data) => {
        if (data.message) {
          toast.success('Preferências atualizadas com sucesso!');
        } else {
          toast.error('Erro ao atualizar as preferências.');
        }
      })
      .catch((error) => {
        console.error('Erro ao atualizar as preferências:', error);
        toast.error('Erro ao atualizar as preferências.');
      });
  };

  const handlePlanUpgrade = () => {
    window.open('https://app.clubedodinheiro.com.br/planos', '_blank');
  };

  const handleCancelSubscription = () => {
    // Lógica para cancelar a assinatura
    toast.info('Funcionalidade de cancelamento de assinatura ainda não implementada.');
  };

  const getPlanDescription = (planLevel) => {
    switch (planLevel) {
      case 1:
        return 'Gratuito';
      case 2:
        return (
          <>
            Premium <FaStar style={{ color: 'gold', fontSize: '1.5em', marginLeft: '10px' }} />
            <span className="plan-description">Acesso a recursos exclusivos.</span>
          </>
        );
      case 3:
        return (
          <>
            Premium + Investimento <FaRocket style={{ color: 'green', fontSize: '1.5em', marginLeft: '10px' }} />
            <span className="plan-description">Tudo no Premium, mais ferramentas de investimento.</span>
          </>
        );
      case 4:
        return (
          <>
            VIP <FaCrown style={{ color: 'gold', fontSize: '1.5em', marginLeft: '10px' }} />
            <span className="plan-description">Acesso total a todos os recursos.</span>
          </>
        );
      default:
        return 'Desconhecido';
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^\(\d{2}\) \d{4,5}-\d{4}$/;
    return regex.test(phone);
  };

  const validateCPF = (cpf) => {
    const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
    return regex.test(cpf);
  };

  const validatePassword = (password) => {
    const lengthRequirement = password.length >= 8;
    const numberRequirement = /\d/.test(password);
    const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return lengthRequirement && numberRequirement && specialCharRequirement;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const formattedPhone = formatPhone(value);
      setUser({ ...user, phone: formattedPhone });
    } else if (name === 'cpf') {
      const formattedCPF = formatCPF(value);
      setUser({ ...user, cpf: formattedCPF });
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const toggleShowPassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const formatPhone = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4,5})(\d{4})$/, '$1-$2');
  };

  const formatCPF = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})$/, '$1-$2');
  };

  return (
    <>
      <ToastContainer />
      <main className="profile-container">
        {/* Dados do Usuário */}
        <div className="profile-box">
          <h2>Dados do Usuário</h2>
          <form onSubmit={handleSaveProfile}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="first-name">Nome *</label>
                <input
                  type="text"
                  id="first-name"
                  name="firstName"
                  value={user.firstName}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="last-name">Sobrenome *</label>
                <input
                  type="text"
                  id="last-name"
                  name="lastName"
                  value={user.lastName}
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={`form-group ${errors.email ? 'error' : ''}`}>
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={user.email}
                  required
                  onChange={handleInputChange}
                />
                {errors.email && <span className="error-message">Email inválido</span>}
              </div>
              <div className={`form-group ${errors.phone ? 'error' : ''}`}>
                <label htmlFor="phone">Telefone *</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={user.phone}
                  required
                  onChange={handleInputChange}
                />
                {errors.phone && <span className="error-message">Telefone inválido</span>}
              </div>
            </div>
            <div className="form-row">
              <div className={`form-group ${errors.cpf ? 'error' : ''}`}>
                <label htmlFor="cpf">CPF *</label>
                <input
                  type="text"
                  id="cpf"
                  name="cpf"
                  value={user.cpf}
                  required
                  disabled
                />
                {errors.cpf && <span className="error-message">CPF inválido</span>}
              </div>
              <div className="form-group">
                <label htmlFor="registration-date">Data de Cadastro *</label>
                <input
                  type="text"
                  id="registration-date"
                  name="registrationDate"
                  value={user.registrationDate}
                  required
                  disabled
                />
              </div>
            </div>
            <button type="submit" className="profile-button" disabled={!isFormValid}>
              Salvar Dados
            </button>
          </form>
        </div>

        {/* Senha e Avatar */}
        <div className="profile-box">
          <h2>Senha e Avatar</h2>
          <form onSubmit={handleSavePassword}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="profile-picture">Foto do Perfil</label>
                <img
                  src={`https://www.gravatar.com/avatar/${md5(user.email)}?s=120&d=identicon`}
                  alt="Foto do Perfil"
                  className="profile-picture"
                />
                <button
                  type="button"
                  className="update-avatar-button"
                  onClick={() => window.open('https://gravatar.com', '_blank')}
                >
                  Atualizar Avatar
                </button>
              </div>
            </div>
            <div className="form-row">
              <div className={`form-group ${passwordErrors.currentPassword ? 'error' : ''}`}>
                <label htmlFor="current-password">Senha Atual *</label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword.currentPassword ? 'text' : 'password'}
                    id="current-password"
                    name="currentPassword"
                    value={passwords.currentPassword}
                    required
                    onChange={handlePasswordInputChange}
                  />
                  <span onClick={() => toggleShowPassword('currentPassword')} className="password-toggle-icon">
                    {showPassword.currentPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <p className="input-hint">Digite sua senha atual para atualizar suas informações.</p>
              </div>
              
              <div className={`form-group ${passwordErrors.newPassword ? 'error' : ''}`}>
                <label htmlFor="new-password">Nova Senha</label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword.newPassword ? 'text' : 'password'}
                    id="new-password"
                    name="newPassword"
                    value={passwords.newPassword}
                    onChange={handlePasswordInputChange}
                  />
                  <span onClick={() => toggleShowPassword('newPassword')} className="password-toggle-icon">
                    {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <p className="input-hint">Deixe em branco se não deseja alterar a senha.</p>
                <div className="password-requirements">
                  <ul>
                    <li className={passwordErrors.passwordRequirements.lengthRequirement ? 'valid' : 'invalid'}>
                      {passwordErrors.passwordRequirements.lengthRequirement ? <FaCheck /> : <FaTimes />} Pelo menos 8 caracteres
                    </li>
                    <li className={passwordErrors.passwordRequirements.numberRequirement ? 'valid' : 'invalid'}>
                      {passwordErrors.passwordRequirements.numberRequirement ? <FaCheck /> : <FaTimes />} Pelo menos 1 número
                    </li>
                    <li className={passwordErrors.passwordRequirements.specialCharRequirement ? 'valid' : 'invalid'}>
                      {passwordErrors.passwordRequirements.specialCharRequirement ? <FaCheck /> : <FaTimes />} Pelo menos 1 caractere especial
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className={`form-group ${passwordErrors.repeatNewPassword ? 'error' : ''}`}>
                <label htmlFor="repeat-new-password">Repetir Nova Senha</label>
                <div className="password-input-wrapper">
                  <input
                    type={showPassword.repeatNewPassword ? 'text' : 'password'}
                    id="repeat-new-password"
                    name="repeatNewPassword"
                    value={passwords.repeatNewPassword}
                    onChange={handlePasswordInputChange}
                  />
                  <span onClick={() => toggleShowPassword('repeatNewPassword')} className="password-toggle-icon">
                    {showPassword.repeatNewPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                {!passwordErrors.passwordMatch && passwords.newPassword !== passwords.repeatNewPassword && (
                  <span className="error-message">As senhas não coincidem.</span>
                )}
              </div>
            </div>

            <button type="submit" className="profile-button" disabled={!isPasswordFormValid}>
              Salvar Senha
            </button>
          </form>
        </div>

        {/* Preferências */}
        <div className="profile-box">
          <h2>Preferências</h2>
          <form onSubmit={handleSavePreferences}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="country">País *</label>
                <select
                  id="country"
                  name="country"
                  value={user.country}
                  required
                  onChange={handleInputChange}
                >
                  <option value="Brasil">Brasil</option>
                  <option value="Irlanda">Irlanda</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="currency">Moeda Principal *</label>
                <select
                  id="currency"
                  name="currency"
                  value={user.currency}
                  required
                  onChange={handleInputChange}
                  disabled // Adiciona o atributo para desabilitar o select
                >
                  <option value="BRL">Real Brasil</option>
                  <option value="EUR">Euro</option>
                </select>

              </div>
              <div className="form-group">
                <label htmlFor="language">Idioma *</label>
                <select
                  id="language"
                  name="language"
                  value={user.language}
                  required
                  onChange={handleInputChange}
                >
                  <option value="portugues">Português</option>
                  <option value="ingles">Inglês</option>
                </select>
              </div>
            </div>
            <button type="submit" className="profile-button" disabled={!isFormValid}>
              Salvar Preferências
            </button>
          </form>
        </div>

        {/* Planos e Assinaturas */}
        <div className="profile-box">
          <h2>Planos e Assinaturas</h2>
          <p>
            <strong>Seu plano atual:</strong> {getPlanDescription(user.planLevel)}
          </p>
          {user.planLevel === 1 && (
            <button
              type="button"
              className="premium-button"
              onClick={handlePlanUpgrade}
            >
              Ativar Assinatura Premium
            </button>
          )}
          {user.planLevel === 2 && (
            <>
              <button
                type="button"
                className="premium-button"
                onClick={handlePlanUpgrade}
              >
                Ativar Assinatura Premium + Investimento
              </button>
              
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancelSubscription}
              >
                Cancelar Assinatura
              </button>
            </>
          )}
          {user.planLevel === 3 && (
            <>
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancelSubscription}
              >
                Cancelar Assinatura
              </button>
            </>
          )}
          {user.planLevel === 4 && (
            <p>Você está no plano VIP. Não há mais opções de upgrade ou cancelamento disponíveis.</p>
          )}
        </div>
      </main>
    </>
  );
};

export default Profile;
