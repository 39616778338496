import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './NovaCarteiraModal.css';

const NovaCarteiraModal = ({ isOpen, onClose }) => {
  const [nome, setNome] = useState('');
  const [tipo, setTipo] = useState('');
  const [saldoInicial, setSaldoInicial] = useState('');
  const [moeda, setMoeda] = useState('');
  const [ativa, setAtiva] = useState(false);
  const [moedasDisponiveis, setMoedasDisponiveis] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const [validation, setValidation] = useState({
    nome: true,
    tipo: true,
    saldoInicial: true,
    moeda: true,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const userId = localStorage.getItem('userId');
      fetch(`/api/users/${userId}/moedas`)
        .then((res) => res.json())
        .then((data) => setMoedasDisponiveis(data))
        .catch((err) => console.error('Erro ao buscar moedas:', err));
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');

    if (!tipo || !moeda) {
      toast.error('Por favor, selecione um tipo e uma moeda.');
      return;
    }

    fetch(`/api/users/${userId}/carteiras`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        nome,
        tipo,
        saldo_inicial: saldoInicial,
        saldo_atual: saldoInicial,
        moeda,
        ativa: ativa ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 'Carteira cadastrada com sucesso.') {
          toast.success('Carteira cadastrada com sucesso!', {
            autoClose: 5000,
          });
          setTimeout(() => {
            closeModal();
          }, 10);
        }
      })
      .catch((err) => {
        console.error('Erro ao cadastrar carteira:', err);
        toast.error('Erro ao cadastrar carteira.');
      });
  };

  const validateForm = () => {
    const validationState = {
      nome: !!nome,
      tipo: !!tipo,
      saldoInicial: !!saldoInicial,
      moeda: !!moeda,
    };
    setValidation(validationState);
    return Object.values(validationState).every(Boolean);
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [nome, tipo, saldoInicial, moeda]);

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('nova-carteira-modal')) {
      closeModal();
    }
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose(); // Fecha o modal
      setIsClosing(false);
      window.location.reload(); // Recarrega a página após o modal fechar
    }, 4000); // 300ms de delay para garantir o efeito de fechamento
  };
  

  return (
    <div className={`nova-carteira-modal ${isOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleOutsideClick}>
      <div className="nova-carteira-modal-content">
        <button className="close-btn" onClick={closeModal}>&times;</button>
        <h2>Cadastrar Nova Carteira</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Nome da Carteira:</label>
            <input
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              className={!validation.nome ? 'invalid' : ''}
              required
            />
          </div>
          <div className="form-group">
            <label>Tipo:</label>
            <select
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
              className={!validation.tipo ? 'invalid' : ''}
              required
            >
              <option value="">Selecione o tipo</option>
              <option value="Conta Corrente">Conta Corrente</option>
              <option value="Poupança">Poupança</option>
              <option value="Investimentos">Investimentos</option>
            </select>
          </div>
          <div className="form-group">
            <label>Saldo Inicial:</label>
            <input
              type="number"
              value={saldoInicial}
              onChange={(e) => setSaldoInicial(e.target.value)}
              className={!validation.saldoInicial ? 'invalid' : ''}
              required
            />
          </div>
          <div className="form-group">
            <label>Moeda:</label>
            <select
              value={moeda}
              onChange={(e) => setMoeda(e.target.value)}
              className={!validation.moeda ? 'invalid' : ''}
              required
            >
              <option value="">Selecione a moeda</option>
              {moedasDisponiveis.map((m) => (
                <option key={m.id_moeda} value={m.moeda}>
                  {m.moeda}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Ativa:</label>
            <input type="checkbox" checked={ativa} onChange={(e) => setAtiva(e.target.checked)} />
          </div>
          <div className="form-group">
            <button className="btn btn-primary" type="submit" disabled={!isFormValid}>
              Cadastrar
            </button>
            <button className="btn btn-secondary" type="button" onClick={closeModal}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NovaCarteiraModal;
