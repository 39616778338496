import React, { useState, useEffect } from 'react';
import './relatorios.css';
import RelatorioCategoria from '../../components/Relatorios/RelatorioCategoria';
import RelatorioMensal from '../../components/Relatorios/RelatorioMensal';
import RelatorioComparativoMensal from '../../components/Relatorios/RelatorioComparativoMensal';
import RelatorioEvolucao from '../../components/Relatorios/RelatorioEvolucao';
import RelatorioPagamento from '../../components/Relatorios/RelatorioPagamento';
import RelatorioResumoPeriodo from '../../components/Relatorios/RelatorioResumoPeriodo';
import RelatorioMetas from '../../components/Relatorios/RelatorioMetas';
import RelatorioCartaoCredito from '../../components/Relatorios/RelatorioCartaoCredito';

const Relatorios = ({ selectedDate }) => {
    const [tipoRelatorioSelecionado, setTipoRelatorioSelecionado] = useState(null);
    const [userId, setUserId] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Define o ano atual por padrão


    // Obter o userId do localStorage ao montar o componente
    useEffect(() => {
        const userDetails = localStorage.getItem('userDetails');
        if (userDetails) {
            const parsedDetails = JSON.parse(userDetails);
            setUserId(parsedDetails.userId);
        } else {
            console.error('User ID não encontrado no localStorage.');
        }
    }, []);

    const handleButtonClick = (relatorio) => {
        setTipoRelatorioSelecionado(relatorio); // Define o tipo de relatório ao clicar em um botão
    };

    const voltarParaOpcoes = () => {
        setTipoRelatorioSelecionado(null);
    };

    const renderRelatorioSelecionado = () => {
        if (!userId) return <p>Carregando dados do usuário...</p>;

        switch (tipoRelatorioSelecionado) {
            case 'Receitas por Categoria':
            case 'Despesas por Categoria':
                return <RelatorioCategoria tipoRelatorio={tipoRelatorioSelecionado} userId={userId} selectedDate={selectedDate} />;
            case 'Receitas por Mês':
            case 'Despesas por Mês':
                return <RelatorioMensal tipoRelatorio={tipoRelatorioSelecionado} userId={userId} selectedDate={selectedDate} />;
            case 'Comparativo de Receitas e Despesas Mensal':
                return <RelatorioComparativoMensal userId={userId} selectedDate={selectedDate} />;
            case 'Evolução das Receitas':
            case 'Evolução das Despesas':
                return <RelatorioEvolucao tipoRelatorio={tipoRelatorioSelecionado} userId={userId} selectedYear={selectedYear} />;
            case 'Despesas por Tipo de Pagamento':
                return <RelatorioPagamento userId={userId} selectedDate={selectedDate} />;
            case 'Resumo Financeiro por Período':
                return <RelatorioResumoPeriodo userId={userId} />;
            case 'Metas Financeiras':
                return <RelatorioMetas userId={userId} />;
            case 'Despesas com Cartão de Crédito':
                return <RelatorioCartaoCredito userId={userId} selectedDate={selectedDate} />;
            default:
                return null;
        }
    };

    

    return (
        <div className="relatorios-container">
            <h2>Relatórios Financeiros Pessoais</h2>

            {tipoRelatorioSelecionado ? (
                <div>
                    <button onClick={voltarParaOpcoes}>Voltar</button>
                    {renderRelatorioSelecionado()}
                </div>
            ) : (
                <div className="relatorios-grid">
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Receitas por Categoria')}>Receitas por Categoria</button>
                        <p>Veja suas receitas organizadas em categorias, como salário, rendas extras, etc.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Despesas por Categoria')}>Despesas por Categoria</button>
                        <p>Veja suas despesas classificadas por categorias, como alimentação, transporte, lazer, etc.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Receitas por Mês')}>Receitas por Mês</button>
                        <p>Acompanhe o total de receitas recebidas em cada mês.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Despesas por Mês')}>Despesas por Mês</button>
                        <p>Acompanhe o total de despesas realizadas a cada mês.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Comparativo de Receitas e Despesas Mensal')}>Comparativo de Receitas e Despesas Mensal</button>
                        <p>Compare suas receitas e despesas mensalmente para entender seu fluxo de caixa.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Evolução das Receitas')}>Evolução das Receitas</button>
                        <p>Veja como suas receitas mudaram ao longo do tempo.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Evolução das Despesas')}>Evolução das Despesas</button>
                        <p>Veja como suas despesas se comportaram ao longo dos meses.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Despesas por Tipo de Pagamento')}>Despesas por Tipo de Pagamento</button>
                        <p>Veja onde você está gastando mais, separado por métodos de pagamento (crédito, débito, etc).</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Resumo Financeiro por Período')}>Resumo Financeiro por Período</button>
                        <p>Obtenha um resumo de receitas, despesas em um período selecionado.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Metas Financeiras')}>Metas Financeiras</button>
                        <p>Acompanhe o progresso das suas metas financeiras, como economizar para uma viagem ou investir.</p>
                    </div>
                    <div className="relatorio-item">
                        <button onClick={() => handleButtonClick('Despesas com Cartão de Crédito')}>Despesas com Cartão de Crédito</button>
                        <p>Veja o total de despesas realizadas com cartões de crédito ao longo do mês.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Relatorios;
