import React, { useState, useEffect } from 'react';




const ModalPagarFatura = ({ cartao, carteiras, valorFatura, show, handleClose, handleConfirm }) => {
    const [selectedCarteira, setSelectedCarteira] = useState('');
    const [valorPagar, setValorPagar] = useState('');

    useEffect(() => {
        if (cartao) {
            // Define o valor a pagar como o valor da fatura, negativo ou positivo
            setValorPagar(valorFatura); 
        }
    }, [cartao, valorFatura]);

    // Se o valor da fatura for 0, o botão de pagamento será desabilitado
    const isPagamentoDesabilitado = !valorPagar || isNaN(valorPagar);

    if (!show || !cartao) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Pagar Fatura: {cartao.nome}</h2>
                <p>
                    Valor total da fatura: 
                    {valorFatura !== 0 
                        ? valorFatura.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) 
                        : 'Nenhuma fatura pendente'}
                </p>
                
                <label>
                    Valor a pagar:
                    <input
                        type="number"
                        step="0.01"
                        value={valorPagar}
                        onChange={(e) => setValorPagar(e.target.value)} // Permite valores negativos
                    />
                </label>
                
                {parseFloat(valorPagar) > 0 && (
                    <label>
                        Selecionar Carteira:
                        <select value={selectedCarteira} onChange={(e) => setSelectedCarteira(e.target.value)}>
                            <option value="">Selecione uma carteira</option>
                            {carteiras.map(carteira => (
                                <option key={carteira.id} value={carteira.id}>
                                    {carteira.nome} - Saldo: {parseFloat(carteira.saldo_atual).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </option>
                            ))}
                        </select>
                    </label>
                )}

                <div className="modal-actions">
                    <button 
                        onClick={() => handleConfirm(selectedCarteira, valorPagar)} 
                        disabled={isPagamentoDesabilitado}
                    >
                        Confirmar Pagamento
                    </button>
                    <button onClick={handleClose}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default ModalPagarFatura;
