import React, { useEffect, useState, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import './css/ReceitasPieChart.css';
import { differenceInDays } from 'date-fns';

const ReceitasPieChart = ({ userId, selectedDate }) => {
    const [dadosReceitas, setDadosReceitas] = useState([]);
    const chartRef = useRef(null);
    const [nivelUsuario, setNivelUsuario] = useState(1); // Valor padrão como 1
    const [dataCadastro, setDataCadastro] = useState(null);
    const [liberado, setLiberado] = useState(false); // Variável para liberar o componente
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId || !selectedDate) {
            console.error('User ID ou Data não fornecidos');
            return;
        }

        const fetchReceitas = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/receitasPorCategoria?selectedDate=${selectedDate}`);
                const data = await response.json();
                if (response.ok) {
                    setDadosReceitas(data.receitasPorCategoria || []);
                } else {
                    console.error('Erro ao buscar as receitas por categoria:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar as receitas por categoria:', error);
            }
        };

        // Função para buscar o nível do usuário e a data de cadastro
        const fetchUserLevel = async () => {
            try {
                const response = await fetch(`/api/users_nivel/${userId}`);
                const data = await response.json();
                if (response.ok) {
                    setNivelUsuario(data.nivel);
                    setDataCadastro(data.data_cadastro);

                    // Verificar se o nível do usuário ou a data de cadastro atende aos critérios
                    if (data.nivel > 1 || isCadastroWithinEightDays(data.data_cadastro)) {
                        setLiberado(true); // Libera o componente
                    } else {
                        setLiberado(false); // Bloqueia o componente
                    }
                } else {
                    console.error('Erro ao buscar o nível do usuário e a data de cadastro:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar o nível do usuário e a data de cadastro:', error);
            }
        };

        fetchUserLevel();
        fetchReceitas();
    }, [userId, selectedDate]);

    useEffect(() => {
        const updateLegendColors = () => {
            const chartInstance = chartRef.current;
            if (chartInstance) {
                const isDarkMode = document.body.classList.contains('dark-mode');
                const fontColor = isDarkMode ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color');

                chartInstance.options.plugins.legend.labels.color = fontColor;
                chartInstance.update();
            }
        };

        const observer = new MutationObserver(() => {
            updateLegendColors();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const isCadastroWithinEightDays = (dataCadastro) => {
        if (!dataCadastro) {
            return false; // Se dataCadastro for null ou undefined, retorna false
        }

        const dataAtual = new Date();
        const [year, month, day] = dataCadastro.split('-'); // Divide a string em partes de ano, mês e dia
        const dataCadastroDate = new Date(year, month - 1, day); // Cria a data considerando que os meses começam em 0 (Janeiro = 0)

        return differenceInDays(dataAtual, dataCadastroDate) <= 7;
    };

    const montarDadosGrafico = () => {
        return {
            labels: dadosReceitas.map(receita => receita.CATEGORIA || 'Sem Categoria'),
            datasets: [
                {
                    data: dadosReceitas.map(receita => receita.totalReceita),
                    backgroundColor: dadosReceitas.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                    hoverBackgroundColor: dadosReceitas.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                },
            ],
        };
    };

    const handleNavigateToReceitas = () => {
        navigate('/lancamentos', { state: { tipoLancamento: 'Receita' } });
    };

    const handleNavigate = () => {
        if (!liberado) {
            // Se o usuário estiver bloqueado, redirecionar para a página de planos
            navigate('/planos');
        } else {
            // Caso contrário, redirecionar para a página de receitas
            handleNavigateToReceitas();
        }
    };

    

    const options = {
        plugins: {
            legend: {
                position: 'left',
                labels: {
                    usePointStyle: true,
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="receitasPieChartCard">
            <div className="card-header">
                Receitas por Categoria
                <button onClick={handleNavigate} className="btn-ver-receitas">
                    Ver Receitas
                </button>

            </div>
            <div className="card-content">
                <div className={`${!liberado ? 'blurred-content' : ''}`}>
                    <Pie ref={chartRef} data={montarDadosGrafico()} options={options} />
                </div>
                {!liberado && (
                    <div className="message-overlay">
                        <p>Para ver este gráfico claramente, assine um plano.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReceitasPieChart;
