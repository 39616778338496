import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import './css/SerenaBot.css';
import { differenceInDays } from 'date-fns';

const SerenaBot = ({ userId, selectedDate }) => {
    const [mensagens, setMensagens] = useState([]);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [typing, setTyping] = useState(false);
    const [nivelUsuario, setNivelUsuario] = useState(null); // Nível do usuário
    const [dataCadastro, setDataCadastro] = useState(null); // Data de cadastro do usuário
    const [liberado, setLiberado] = useState(false); // Estado para controlar a liberação do componente

    // Função para verificar se a data de cadastro está dentro de 7 dias
    const isCadastroWithinEightDays = (dataCadastro) => {
        if (!dataCadastro) return false;

        const dataAtual = new Date();
        const [year, month, day] = dataCadastro.split('-');
        const dataCadastroDate = new Date(year, month - 1, day);
        const diferencaDias = differenceInDays(dataAtual, dataCadastroDate);

        return diferencaDias <= 7;
    };

    // Função para buscar o nível do usuário e a data de cadastro
    const fetchUserLevel = async () => {
        try {
            const response = await fetch(`/api/users_nivel/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setNivelUsuario(data.nivel);
                setDataCadastro(data.data_cadastro);

                // Verificar se o nível do usuário ou a data de cadastro atende aos critérios
                if (data.nivel > 1 || isCadastroWithinEightDays(data.data_cadastro)) {
                    setLiberado(true); // Libera o componente
                } else {
                    setLiberado(false); // Bloqueia o componente
                }
            } else {
                console.error('Erro ao buscar o nível do usuário e a data de cadastro:', data.message);
            }
        } catch (error) {
            console.error('Erro ao buscar o nível do usuário e a data de cadastro:', error);
        }
    };

    // Função para buscar as mensagens
    const fetchMensagens = async () => {
        try {
            const response = await fetch(`/api/users/${userId}/serenaMessages?date=${selectedDate}`);
            const data = await response.json();
            if (response.ok) {
                setMensagens(data.mensagens || []);
            } else {
                console.error('Erro ao buscar mensagens do robô:', data.message);
            }
        } catch (error) {
            console.error('Erro ao buscar mensagens do robô:', error);
        }
    };

    // Hook para buscar o nível do usuário e a data de cadastro
    useEffect(() => {
        if (userId) {
            fetchUserLevel();
        }
    }, [userId]);

    // Hook para buscar as mensagens somente quando o componente for liberado
    useEffect(() => {
        if (liberado) {
            fetchMensagens();
        }
    }, [liberado, userId, selectedDate]);

    // Exibir próxima mensagem quando apropriado
    useEffect(() => {
        if (liberado && mensagens.length > 0 && currentMessageIndex < mensagens.length) {
            displayNextMessage();
        }
    }, [mensagens, currentMessageIndex, liberado]);

    const displayNextMessage = async () => {
        setTyping(true);
        await new Promise(resolve => setTimeout(resolve, 5000)); // Simulando digitação
        setTyping(false);
        setCurrentMessageIndex(prevIndex => prevIndex + 1);
    };

    const getMessageClass = (tipo) => {
        switch (tipo) {
            case 'positivo':
                return 'serena-message positive-message';
            case 'negativo':
                return 'serena-message negative-message';
            case 'alerta':
                return 'serena-message alert-message';
            default:
                return 'serena-message neutral-message';
        }
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="serenaBotCard">
            <div className="card-header">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faRobot} style={{ marginRight: '10px' }} />
                    <span>Serena</span>
                </div>
            </div>
            <div className="card-content">
                <div className={`${!liberado ? 'blurred-content' : ''}`}>
                    {mensagens.slice(0, currentMessageIndex).map((mensagem, index) => (
                        <div key={index} className="serena-message-wrapper">
                            <div className="serena-icon" />
                            <div className={getMessageClass(mensagem.tipo)}>
                                <p>{mensagem.texto}</p>
                            </div>
                        </div>
                    ))}
                    {typing && (
                        <div className="serena-message-wrapper">
                            <div className="serena-icon" />
                            <div className="serena-message neutral-message typing-indicator">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                        </div>
                    )}
                </div>
                {!liberado && (
                    <div className="message-overlay">
                        <p>Para ver esta mensagem claramente, assine um plano.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SerenaBot;
