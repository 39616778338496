import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/styles/main.css';
import './signup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const validateCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

  let soma = 0;
  let resto;

  for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

const validatePhone = (phone) => {
  const phoneRegex = /^\(\d{2}\)\s?\d{4,5}-?\d{4}$/;
  return phoneRegex.test(phone);
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cpf: '',
    phone: '',
    email: '',
    password: '',
    country: 'brasil',
    currency: 'BRL',
    language: 'portugues',
    terms: false,
  });

  const [passwordValidity, setPasswordValidity] = useState({
    length: false,
    number: false,
    specialChar: false,
  });

  const [validation, setValidation] = useState({
    cpf: false,
    phone: false,
    email: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [errorMessage, setErrorMessage] = useState('');
  const [visibleExamples, setVisibleExamples] = useState({}); // Estado para armazenar a visibilidade dos exemplos

  const navigate = useNavigate();

  useEffect(() => {
    const isValid =
      formData.firstName &&
      formData.lastName &&
      formData.cpf &&
      formData.phone &&
      formData.email &&
      formData.password &&
      formData.terms &&
      passwordValidity.length &&
      passwordValidity.number &&
      passwordValidity.specialChar &&
      validation.cpf &&
      validation.phone &&
      validation.email;

    setIsFormValid(isValid);
  }, [formData, passwordValidity, validation]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'password') {
      setPasswordValidity({
        length: value.length >= 8,
        number: /\d/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      });
    }

    if (name === 'cpf') {
      setValidation({ ...validation, cpf: validateCPF(value) });
    }

    if (name === 'phone') {
      setValidation({ ...validation, phone: validatePhone(value) });
    }

    if (name === 'email') {
      setValidation({ ...validation, email: validateEmail(value) });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const cleanCpf = formData.cpf.replace(/[^\d]+/g, '');
      const cleanPhone = formData.phone.replace(/[^\d]+/g, '');

      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nome: formData.firstName,
          sobrenome: formData.lastName,
          cpf: cleanCpf,
          tel_contato: cleanPhone,
          email: formData.email,
          senha: formData.password,
          moeda_primaria: formData.currency,
          idioma_padrao: formData.language,
          pais_padrao: formData.country,
        }),
      });

      if (response.ok) {
        toast.success('Cadastro realizado com sucesso!');
        setTimeout(() => {
          toast.info('Redirecionando para a página...');
          setTimeout(() => {
            navigate('/planos');
          }, 7000);
        }, 2000); // Exibe a segunda mensagem após 2 segundos
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Erro ao cadastrar usuário.');
      }
    } catch (error) {
      console.error('Erro ao realizar a requisição:', error);
      setErrorMessage('Erro ao realizar a requisição: ' + error.message);
    }
  };

  const showExample = (field) => {
    setVisibleExamples((prevVisibleExamples) => ({
      ...prevVisibleExamples,
      [field]: !prevVisibleExamples[field],
    }));
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <main>
      <div className="signup-container">
        <div className="signup-box">
          <ToastContainer /> {/* Componente para exibir os toasts */}
          <h2>Cadastro de Usuário</h2>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <form id="signup-form" onSubmit={handleSubmit}>
            {/* Formulário continua igual */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="first-name">
                  Nome <span className="required">*</span>
                  <span className="info" onClick={() => showExample('first-name')}>?</span>
                </label>
                <input
                  type="text"
                  id="first-name"
                  name="firstName"
                  required
                  autoComplete="given-name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <small 
                  id="first-name-example" 
                  className="example" 
                  style={{ display: visibleExamples['first-name'] ? 'block' : 'none' }}
                >
                  Ex: João
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="last-name">
                  Sobrenome <span className="required">*</span>
                  <span className="info" onClick={() => showExample('last-name')}>?</span>
                </label>
                <input
                  type="text"
                  id="last-name"
                  name="lastName"
                  required
                  autoComplete="family-name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <small 
                  id="last-name-example" 
                  className="example" 
                  style={{ display: visibleExamples['last-name'] ? 'block' : 'none' }}
                >
                  Ex: Silva
                </small>
              </div>
            </div>
            {/* Restante do formulário */}
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="cpf">
                  CPF <span className="required">*</span>
                  <span className="info" onClick={() => showExample('cpf')}>?</span>
                </label>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                  id="cpf"
                  name="cpf"
                  required
                  autoComplete="off"
                  value={formData.cpf}
                  onChange={handleChange}
                  className="cpf-input"
                />
                <small 
                  id="cpf-example" 
                  className="example" 
                  style={{ display: visibleExamples['cpf'] ? 'block' : 'none' }}
                >
                  Ex: 123.456.789-09
                </small>
                <small id="cpf-error" style={{ color: validation.cpf ? 'green' : 'red', display: 'block' }}>
                  {validation.cpf ? 'CPF válido' : 'CPF inválido'}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="phone">
                  Telefone de Contato <span className="required">*</span>
                  <span className="info" onClick={() => showExample('phone')}>?</span>
                </label>
                <MaskedInput
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  id="phone"
                  name="phone"
                  required
                  autoComplete="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  className="phone-input"
                />
                <small 
                  id="phone-example" 
                  className="example" 
                  style={{ display: visibleExamples['phone'] ? 'block' : 'none' }}
                >
                  Ex: (99) 99999-9999
                </small>
                <small id="phone-error" style={{ color: validation.phone ? 'green' : 'red', display: 'block' }}>
                  {validation.phone ? 'Telefone válido' : 'Telefone inválido'}
                </small>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email">
                  Email <span className="required">*</span>
                  <span className="info" onClick={() => showExample('email')}>?</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <small 
                  id="email-example" 
                  className="example" 
                  style={{ display: visibleExamples['email'] ? 'block' : 'none' }}
                >
                  Ex: joao@example.com
                </small>
                <small id="email-error" style={{ color: validation.email ? 'green' : 'red', display: 'block' }}>
                  {validation.email ? 'Email válido' : 'Email inválido'}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  Senha <span className="required">*</span>
                  <span className="info" onClick={() => showExample('password')}>?</span>
                </label>
                <div className="password-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    required
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="password-toggle"
                    onClick={toggleShowPassword}
                    aria-label={showPassword ? "Ocultar senha" : "Mostrar senha"}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
                <small 
                  id="password-example" 
                  className="example" 
                  style={{ display: visibleExamples['password'] ? 'block' : 'none' }}
                >
                  Ex: Senha@123
                </small>
                <small id="password-error" style={{ color: 'red', display: passwordValidity.length && passwordValidity.number && passwordValidity.specialChar ? 'none' : 'block' }}>
                  Senha inválida
                </small>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="country">
                  País <span className="required">*</span>
                </label>
                <select
                  id="country"
                  name="country"
                  required
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="brasil">Brasil</option>
                  <option value="irlanda">Irlanda</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="currency">
                  Moeda Principal <span className="required">*</span>
                </label>
                <select
                  id="currency"
                  name="currency"
                  required
                  value={formData.currency}
                  onChange={handleChange}
                >
                  <option value="BRL">Real Brasil</option>
                  <option value="EUR">Euro</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="language">
                  Idioma <span className="required">*</span>
                </label>
                <select
                  id="language-select"
                  className="language-select"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                >
                  <option value="portugues">Português</option>
                  <option value="ingles">Inglês</option>
                </select>
              </div>
              <div className="form-group checkbox">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  required
                  checked={formData.terms}
                  onChange={handleChange}
                />
                <label htmlFor="terms">
                  Concordo com os <Link to="#">termos e políticas</Link> <span className="required">*</span>
                </label>
              </div>
            </div>
            <button
              type="submit"
              className="signup-button"
              disabled={!isFormValid}
            >
              Cadastrar
            </button>
          </form>
          <div className="password-requirements">
            <p>A sua senha deve conter:</p>
            <ul>
              <li id="length-requirement">
                <FontAwesomeIcon icon={passwordValidity.length ? faCheck : faTimes} /> Pelo menos 8 caracteres
              </li>
              <li id="number-requirement">
                <FontAwesomeIcon icon={passwordValidity.number ? faCheck : faTimes} /> Pelo menos 1 número
              </li>
              <li id="special-char-requirement">
                <FontAwesomeIcon icon={passwordValidity.specialChar ? faCheck : faTimes} /> Pelo menos 1 caractere especial
              </li>
            </ul>
          </div>
          <p className="login-link">
            Já tem conta? <Link to="/login">Entrar</Link>
          </p>
        </div>
      </div>
    </main>
  );
};

export default Signup;
