import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'; // Certifique-se de ter o react-toastify instalado
import 'react-toastify/dist/ReactToastify.css';
import './LancamentoEditModal.css'; // Arquivo de CSS para o modal

const LancamentoEditModal = ({ show, onClose, lancamento, userId, refreshData }) => {
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');
  const [valorFormatado, setValorFormatado] = useState(''); // Estado para o valor formatado
  const [data, setData] = useState('');
  const [tipoLancamento, setTipoLancamento] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [formasDePagamento, setFormasDePagamento] = useState([]);
  
  const [valorOriginal, setValorOriginal] = useState('');
  const [tipoLancamentoOriginal, setTipoLancamentoOriginal] = useState('');

  const [tipoLancamentoAlterado, setTipoLancamentoAlterado] = useState(false);
  const [valorAlterado, setValorAlterado] = useState(false);

  // Buscar as formas de pagamento quando o modal é aberto
  useEffect(() => {
    if (userId && show) {
      fetchFormasDePagamento(userId);
    }

    if (lancamento) {
      setDescricao(lancamento.DESCRICAO || '');
      setValor(lancamento.VALOR || '');
      setValorOriginal(lancamento.VALOR || '');
      setValorFormatado(formatCurrency(lancamento.VALOR || 0)); // Formatar o valor original
      setData(lancamento.DATA ? new Date(lancamento.DATA).toISOString().substring(0, 10) : '');
      setTipoLancamento(lancamento.TIPO_LANCAMENTO || '');
      setTipoLancamentoOriginal(lancamento.TIPO_LANCAMENTO || '');
      setFormaPagamento(lancamento.FORMA_DE_PAGAMENTO || '');
      setTipoLancamentoAlterado(false);
      setValorAlterado(false);
    }
  }, [lancamento, show, userId]);

  // Função para buscar as formas de pagamento
  const fetchFormasDePagamento = async (userId) => {
    try {
      const response = await fetch(`/api/formasdepagamento/${userId}`);
      const data = await response.json();
      if (response.ok) {
        setFormasDePagamento(data.formas_de_pagamento);
      } else {
        console.error('Erro ao buscar as formas de pagamento:', data.message);
      }
    } catch (error) {
      console.error('Erro ao buscar as formas de pagamento:', error);
    }
  };

  // Função para formatar o valor como moeda
  const formatCurrency = (value) => {
    const formatted = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
    return formatted;
  };

  // Função para manipular a mudança no valor (formato moeda)
  const handleValorChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
    const numericValue = Number(rawValue) / 100; // Divide por 100 para tratar como valor decimal

    setValor(numericValue);
    setValorFormatado(formatCurrency(numericValue)); // Atualizar o valor formatado como moeda
    setValorAlterado(numericValue !== valorOriginal); // Comparar com o valor original
    setTipoLancamentoAlterado(false); // Reseta o estado de alteração de tipo
  };

  // Função para detectar mudança no tipo de lançamento
  const handleTipoLancamentoChange = (e) => {
    const newTipoLancamento = e.target.value;
    setTipoLancamento(newTipoLancamento);
    setTipoLancamentoAlterado(newTipoLancamento !== tipoLancamentoOriginal); // Comparar com o tipo original
    setValorAlterado(false); // Reseta o estado de alteração de valor
  };

  // Fechar o modal ao pressionar a tecla "Esc"
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (show) {
      window.addEventListener('keydown', handleEsc);
    } else {
      window.removeEventListener('keydown', handleEsc);
    }

    return () => window.removeEventListener('keydown', handleEsc);
  }, [show, onClose]);

  // Fechar o modal ao clicar fora dele
  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('lancamento-modal')) {
      onClose();
    }
  };

 // Função para salvar o lançamento editado
const handleSave = async () => {
  try {
    const updatedLancamento = {
      tipoLancamento,
      valor,
      descricao,
      formaPagamento,
      data,
    };

    const response = await fetch(`/api/users/${userId}/lancamentos/${lancamento.ID_LANCAMENTO}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedLancamento),
    });

    if (response.ok) {
      toast.success('Lançamento atualizado com sucesso!');
      if (typeof refreshData === 'function') {
        refreshData(); // Certifique-se de que refreshData seja uma função antes de chamá-la
      }
      setTimeout(() => {
        onClose(); // Fechar o modal ou janela atual
        window.location.reload(); // Recarregar a página após fechar o modal
      }, 5000); // Delay de 500ms para garantir que o toast seja exibido antes de recarregar
    } else {
      const errorData = await response.json(); // Capturar o erro retornado pela API
      throw new Error(`Erro ao atualizar lançamento: ${errorData.message}`); // Lançar o erro para ser tratado no catch
    }
  } catch (error) {
    // Tratar erros capturados pelo fetch ou jogados explicitamente
    console.error('Erro ao salvar o lançamento:', error.message);
    toast.error(error.message || 'Erro ao salvar o lançamento.');
  }
};


  if (!show) return null;

  return (
    <div className={`lancamento-modal ${show ? 'show' : 'hide'}`} onClick={handleOverlayClick}>
      <div className="lancamento-modal-content">
        <button className="close-btn" onClick={onClose}>×</button>
        <h2>Editar Lançamento</h2>

        <div className="form-group">
          <label>Data</label>
          <input 
            type="date" 
            value={data} 
            onChange={(e) => setData(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Descrição</label>
          <input 
            type="text" 
            value={descricao} 
            onChange={(e) => setDescricao(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Valor</label>
          <input 
            type="text" 
            value={valorFormatado} 
            onChange={handleValorChange} 
            disabled={tipoLancamentoAlterado} // Desabilitar valor se o tipo for alterado
          />
        </div>
        {lancamento.TIPO_LANCAMENTO !== 'Despesa em Cartão' && (
          <div className="form-group">
            <label>Tipo de Lançamento</label>
            <select 
              value={tipoLancamento} 
              onChange={handleTipoLancamentoChange}
              disabled={valorAlterado} // Desabilitar tipo de lançamento se o valor foi alterado
            >
              <option value="Receita">Receita</option>
              <option value="Despesa">Despesa</option>
            </select>
          </div>
        )}
        <div className="form-group">
          <label>Forma de Pagamento</label>
          <select 
            value={formaPagamento} 
            onChange={(e) => setFormaPagamento(e.target.value)}>
            {formasDePagamento.length > 0 ? formasDePagamento.map((forma) => (
              <option key={forma.id_formapagamento} value={forma.nome_formapagamento}>
                {forma.nome_formapagamento}
              </option>
            )) : <option value="">Nenhuma forma de pagamento disponível</option>}
          </select>
        </div>
        
        <div className="modal-actions">
          <button className="btn btn-primary" onClick={handleSave}>Salvar</button>
          <button className="btn btn-secondary" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default LancamentoEditModal;
