import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import html2pdf from 'html2pdf.js'; // Importando a biblioteca html2pdf

// Registrar os componentes do ChartJS
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// Função para mapear o número do mês para o nome do mês
const getNomeMes = (numeroMes) => {
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return meses[numeroMes - 1]; // Subtraímos 1 porque o índice do array começa em 0
};

const RelatorioComparativoMensal = ({ userId, selectedDate }) => {
    const [dadosRelatorio, setDadosRelatorio] = useState([]);
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState(null);

    useEffect(() => {
        buscarRelatorio();
    }, [userId, selectedDate]);

    const buscarRelatorio = async () => {
        setLoading(true);
        setErro(null);

        try {
            const response = await axios.get(`/api/users/${userId}/relatorios/comparativo-mensal?date=${selectedDate}`);
            setDadosRelatorio(response.data);
        } catch (error) {
            console.error('Erro ao buscar comparativo mensal:', error);
            setErro('Erro ao carregar o relatório.');
        } finally {
            setLoading(false);
        }
    };

    const formatCurrency = (value, currency) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency,
        }).format(value);
    };

    const formatarDadosParaGrafico = () => {
        const labels = dadosRelatorio.map(item => getNomeMes(item.mes));
        const receitas = dadosRelatorio.map(item => item.total_receitas);
        const despesas = dadosRelatorio.map(item => item.total_despesas);

        return {
            labels,
            datasets: [
                {
                    label: 'Receitas',
                    data: receitas,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Despesas',
                    data: despesas,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const renderizarGrafico = () => {
        const dadosGrafico = formatarDadosParaGrafico();
        if (!dadosGrafico) return <p>Nenhum dado disponível para o gráfico.</p>;

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div id="grafico-bar" style={{ width: '60%', height: '60%' }}>
                    <Bar data={dadosGrafico} />
                </div>
            </div>
        );
    };

    const handleImprimir = () => {
        document.body.classList.add('esconder-icones'); // Adiciona a classe para esconder os ícones
        const printWindow = window.open('', '_blank');
    
        // Capturar o gráfico como uma imagem
        const canvas = document.querySelector('#grafico-bar canvas');
        const imageData = canvas.toDataURL('image/png'); // Converter o canvas para uma imagem PNG
    
        printWindow.document.write(`
            <html>
                <head>
                    <title>Relatório Comparativo de Receitas e Despesas - ${selectedDate}</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h2 { text-align: center; margin-bottom: 20px; }
                        table { width: 100%; border-collapse: collapse; margin-top: 20px; }
                        th, td { border: 1px solid black; padding: 8px; text-align: center; }
                        img { display: block; margin: 0 auto 30px auto; width: 400px; height: 400px; } /* Centraliza e ajusta o gráfico */
                    </style>
                </head>
                <body>
                    <h2>Relatório Comparativo de Receitas e Despesas - Mês: ${selectedDate}</h2>
                    <img src="${imageData}" alt="Gráfico do relatório" /> <!-- Exibir o gráfico capturado como imagem -->
                    ${document.querySelector('.relatorio-detalhado').innerHTML}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        document.body.classList.remove('esconder-icones'); // Remove a classe após a impressão
    };

    const handleGerarPDF = () => {
        document.body.classList.add('esconder-icones'); // Adiciona a classe para esconder os ícones
        const element = document.querySelector('.relatorio-detalhado');
        const opt = {
            margin: [0.5, 0.5, 0.5, 0.5],  // Margens menores para o PDF
            filename: `Relatorio_Comparativo_${selectedDate}.pdf`,
            image: { type: 'jpeg', quality: 1 },  // Qualidade da imagem em 100%
            html2canvas: { scale: 3 },  // Scale alto para melhorar a resolução
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }  // Tamanho de papel padrão (Letter)
        };

        html2pdf().from(element).set(opt).save().then(() => {
            document.body.classList.remove('esconder-icones'); // Remove a classe após gerar o PDF
        });
    };

    return (
        <div className="relatorio-detalhado">
            <h2>Comparativo de Receitas e Despesas Mensal</h2>
            {erro && <p style={{ color: 'red' }}>{erro}</p>}
            {loading ? (
                <p>Carregando...</p>
            ) : (
                <div>
                    <div className="icone-acoes-relatorio">
                        <button onClick={handleImprimir}>
                            <FaPrint size={20} title="Imprimir" />
                        </button>
                        <button onClick={handleGerarPDF}>
                            <FaFilePdf size={20} title="Gerar PDF" />
                        </button>
                    </div>

                    {/* Renderizar o gráfico */}
                    {renderizarGrafico()}

                    {/* Renderizar a tabela */}
                    <table>
                        <thead>
                            <tr>
                                <th>Mês</th>
                                <th>Receitas</th>
                                <th>Despesas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dadosRelatorio.length > 0 ? (
                                dadosRelatorio.map((item, index) => (
                                    <tr key={index}>
                                        <td>{getNomeMes(item.mes)}</td>
                                        <td>{formatCurrency(item.total_receitas, 'BRL')}</td>
                                        <td>{formatCurrency(item.total_despesas, 'BRL')}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Nenhum dado disponível</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default RelatorioComparativoMensal;
