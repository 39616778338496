import React, { useEffect, useState, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import './css/CarteirasPieChart.css';
import { differenceInDays } from 'date-fns';

const CarteirasPieChart = ({ userId }) => {
    const [dadosCarteiras, setDadosCarteiras] = useState([]);
    const chartRef = useRef(null);
    const [nivelUsuario, setNivelUsuario] = useState(1);
    const [dataCadastro, setDataCadastro] = useState(null);
    const [liberado, setLiberado] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) {
            console.error('User ID não fornecido');
            return;
        }

        const fetchCarteiras = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/carteiras`);
                const data = await response.json();
                if (response.ok) {
                    setDadosCarteiras(data.carteiras || []);
                } else {
                    console.error('Erro ao buscar as carteiras:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar as carteiras:', error);
            }
        };

        const fetchUserLevel = async () => {
            try {
                const response = await fetch(`/api/users_nivel/${userId}`);
                const data = await response.json();
                if (response.ok) {
                    setNivelUsuario(data.nivel);
                    setDataCadastro(data.data_cadastro);

                    if (data.nivel > 1 || isCadastroWithinEightDays(data.data_cadastro)) {
                        setLiberado(true); // Libera o componente
                    } else {
                        setLiberado(false); // Bloqueia o componente
                    }
                } else {
                    console.error('Erro ao buscar o nível do usuário e a data de cadastro:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar o nível do usuário e a data de cadastro:', error);
            }
        };

        fetchUserLevel();
        fetchCarteiras();
    }, [userId]);

    useEffect(() => {
        const updateLegendColors = () => {
            const chartInstance = chartRef.current;
            if (chartInstance) {
                const isDarkMode = document.body.classList.contains('dark-mode');
                const fontColor = isDarkMode
                    ? getComputedStyle(document.body).getPropertyValue('--light-color')
                    : getComputedStyle(document.body).getPropertyValue('--dark-color');

                chartInstance.options.plugins.legend.labels.color = fontColor;
                chartInstance.update();
            }
        };

        const observer = new MutationObserver(() => {
            updateLegendColors();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const isCadastroWithinEightDays = (dataCadastro) => {
        if (!dataCadastro) return false;

        const dataAtual = new Date();
        const [year, month, day] = dataCadastro.split('-');
        const dataCadastroDate = new Date(year, month - 1, day);

        return differenceInDays(dataAtual, dataCadastroDate) <= 7;
    };

    const montarDadosGrafico = () => {
        return {
            labels: dadosCarteiras.map(carteira => carteira.nome),
            datasets: [
                {
                    data: dadosCarteiras.map(carteira => carteira.saldo_atual),
                    backgroundColor: dadosCarteiras.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                    hoverBackgroundColor: dadosCarteiras.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                },
            ],
        };
    };

    const handleNavigateToCarteiras = () => {
        if (!liberado) {
            navigate('/planos'); // Redirecionar para planos se bloqueado
        } else {
            navigate('/carteiras'); // Redirecionar para carteiras se liberado
        }
    };

    const options = {
        plugins: {
            legend: {
                position: 'left',
                labels: {
                    usePointStyle: true,
                    color: document.body.classList.contains('dark-mode')
                        ? getComputedStyle(document.body).getPropertyValue('--light-color')
                        : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="carteirasPieChartCard">
            <div className="card-header">
                Minhas Carteiras
                <button onClick={handleNavigateToCarteiras} className="btn-ver-carteiras">
                    Ver Carteiras
                </button>
            </div>
            <div className="card-content">
                <div className={`${!liberado ? 'blurred-content' : ''}`}>
                    <Pie ref={chartRef} data={montarDadosGrafico()} options={options} />
                </div>
                {!liberado && (
                    <div className="message-overlay">
                        <p>Para ver este gráfico claramente, assine um plano.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CarteirasPieChart;
