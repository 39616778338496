import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/DespesasTable.css';
import { differenceInDays } from 'date-fns';

const DespesasTable = ({ userId, selectedDate }) => {
    const [despesas, setDespesas] = useState([]);
    const [nivelUsuario, setNivelUsuario] = useState(1); // Valor padrão como 1
    const [dataCadastro, setDataCadastro] = useState(null); // Data de cadastro do usuário
    const [liberado, setLiberado] = useState(false); // Estado para controlar a liberação do componente
    const navigate = useNavigate();

    // Função para verificar se a data de cadastro está dentro de 7 dias
    const isCadastroWithinEightDays = (dataCadastro) => {
        if (!dataCadastro) return false;

        const dataAtual = new Date();
        const [year, month, day] = dataCadastro.split('-');
        const dataCadastroDate = new Date(year, month - 1, day);
        const diferencaDias = differenceInDays(dataAtual, dataCadastroDate);

        return diferencaDias <= 7; // Retorna true se o cadastro foi feito nos últimos 7 dias
    };

    // Função para buscar o nível do usuário e a data de cadastro
    const fetchUserLevel = async () => {
        try {
            const response = await fetch(`/api/users_nivel/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setNivelUsuario(data.nivel);
                setDataCadastro(data.data_cadastro);

                // Verificar se o nível do usuário ou a data de cadastro atende aos critérios
                if (data.nivel > 1 || isCadastroWithinEightDays(data.data_cadastro)) {
                    setLiberado(true); // Libera o componente
                } else {
                    setLiberado(false); // Bloqueia o componente
                }
            } else {
                console.error('Erro ao buscar o nível do usuário e a data de cadastro:', data.message);
            }
        } catch (error) {
            console.error('Erro ao buscar o nível do usuário e a data de cadastro:', error);
        }
    };

    // Função para buscar as despesas
    const fetchDespesas = async () => {
        if (!userId || !selectedDate) {
            console.error('User ID ou data não fornecidos');
            return;
        }

        // Limpa as despesas ao começar a buscar novos dados
        setDespesas([]);

        try {
            const response = await fetch(`/api/users/${userId}/despesas?selectedDate=${selectedDate}`);
            const data = await response.json();
            if (response.ok) {
                if (data.despesas && data.despesas.length > 0) {
                    setDespesas(data.despesas);
                } else {
                    setDespesas([]); // Se não houver despesas, limpa o estado
                }
            } else {
                console.error('Erro ao buscar as despesas:', data.message);
                setDespesas([]); // Limpa o estado se houver erro
            }
        } catch (error) {
            console.error('Erro ao buscar as despesas:', error);
            setDespesas([]); // Limpa o estado em caso de erro
        }
    };

    useEffect(() => {
        fetchUserLevel();
        fetchDespesas();
    }, [userId, selectedDate]); // Dependências para atualizar os dados ao mudar userId ou selectedDate

    const handleNavigateToDespesas = () => {
        if (!liberado) {
            navigate('/planos');
        } else {
            navigate('/lancamentos', { state: { tipoLancamento: 'Despesa' } });
        }
    };

    const formatCurrency = (value, currency) => {
        try {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(value);
        } catch (e) {
            console.error('Invalid currency code:', currency);
            return value;
        }
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="despesasTableCard">
            <div className="card-header">
                Lançamentos de Despesas
                <button onClick={handleNavigateToDespesas} className="btn-ver-despesas">
                    Ver Detalhes
                </button>
            </div>
            <div className="card-content">
                {!liberado && nivelUsuario === 1 && despesas.length > 3 && (
                    <div className="message-overlay">
                        <p>Para ver mais despesas, assine um plano.</p>
                    </div>
                )}
                {despesas.length === 0 ? (
                    <p className="no-despesas-message">Nenhuma despesa encontrada para este mês.</p>
                ) : (
                    <table className={`despesas-table ${nivelUsuario === 1 && despesas.length > 3 ? 'blurred' : ''}`}>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Carteira</th>
                                <th>Forma de Pagamento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {despesas.map((despesa, index) => (
                                <tr key={index} className={index >= 3 && nivelUsuario === 1 && !liberado ? 'embasado' : ''}>
                                    <td>{new Date(despesa.DATA).toLocaleDateString('pt-BR')}</td>
                                    <td>{despesa.DESCRICAO}</td>
                                    <td>{formatCurrency(despesa.VALOR, despesa.moeda)}</td>
                                    <td>{despesa.CARTEIRA}</td>
                                    <td>{despesa.FORMA_DE_PAGAMENTO}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default DespesasTable;
