import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaEdit, FaPlus, FaFileImport, FaTrash, FaSave, FaSync } from 'react-icons/fa'; // Adicionado FaSync para recorrência
import { Modal } from 'react-bootstrap';
import * as pdfjsLib from 'pdfjs-dist';
import './lancamentos.css';
import { toast } from 'react-toastify';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { FaArrowDown, FaArrowUp, FaCreditCard } from 'react-icons/fa'; // Importando os ícones
import ConfirmationModal from './ConfirmationModal';
import LancamentoEditModal from './LancamentoEditModal';
import LancamentoModal from '../../modals/LancamentoModal';  // Importe o modal aqui



pdfjsLib.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

const mapeamentoBancos = {
  'BancoItau': {
    data: 'data',
    descricao: 'lançamentos',
    valor: 'valor (R$)',
  },
  'BancoInter': {
    data: 'Data Lançamento',
    descricao: 'Descrição',
    valor: 'Valor',
  },
};

const Lancamentos = ({ selectedDate }) => {
  const [lancamentos, setLancamentos] = useState([]);
  const [carteiras, setCarteiras] = useState([]);
  const [selectedCarteira, setSelectedCarteira] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false); // Estado para recorrências
  const [recorrencias, setRecorrencias] = useState([]); // Estado para armazenar recorrências
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [selectedBank, setSelectedBank] = useState(''); // Banco selecionado
  const [tableData, setTableData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false); // Estado para modal de edição
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false); // Estado para modal de confirmação de exclusão
  const [recurrenceToDelete, setRecurrenceToDelete] = useState(null); // Armazena a recorrência a ser deletada
  const [selectedRecurrence, setSelectedRecurrence] = useState(null); // Estado para armazenar a recorrência selecionada para edição
  const [newEndDate, setNewEndDate] = useState(''); // Nova data final para edição
  const userId = localStorage.getItem('userId');
  const location = useLocation();
  const tipoLancamento = location.state?.tipoLancamento || null;

  const [lancamentoToDelete, setLancamentoToDelete] = useState(null);
  const [isRecorrente, setIsRecorrente] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showLancamentoEditModal, setShowLancamentoEditModal] = useState(false);
  const [lancamentoToEdit, setLancamentoToEdit] = useState(null);

  const [showLancamentoModal, setShowLancamentoModal] = useState(false);


  const handleAddNew = () => {
    setShowLancamentoModal(true);  // Abre o modal
  };
  
  const handleCloseLancamentoModal = () => {
    setShowLancamentoModal(false);  // Fecha o modal
  };

  
  const handleDeleteClick = (lancamento) => {
    setLancamentoToDelete(lancamento);
    setShowModal(true);
  };

 



  useEffect(() => {
    if (userId && selectedDate) {
      fetchLancamentos();
      fetchCarteiras();
    }
  }, [userId, selectedDate, tipoLancamento]);

  const fetchCarteiras = async () => {
    try {
      const response = await fetch(`/api/users/${userId}/carteiras`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setCarteiras(data.carteiras);
    } catch (error) {
      console.error('Erro ao buscar carteiras:', error);
    }
  };

  const fetchLancamentos = async () => {
    try {
      const response = await fetch(`/api/users/${userId}/lancamentoreceitadespesa?selectedDate=${selectedDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      const lancamentosFiltrados = tipoLancamento
        ? data.lancamentos.filter(lancamento =>
            tipoLancamento === 'Despesa'
              ? lancamento.TIPO_LANCAMENTO === 'Despesa' || lancamento.TIPO_LANCAMENTO === 'Despesa em Cartão'
              : lancamento.TIPO_LANCAMENTO === 'Receita'
          )
        : data.lancamentos;

      if (response.ok) {
        setLancamentos(lancamentosFiltrados || []);
      }
    } catch (error) {
      console.error('Erro ao buscar os lançamentos:', error);
    }
  };

  const fetchRecorrencias = async () => {
    try {
      const response = await fetch(`/api/users/${userId}/recorrencias`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.ok) {
        setRecorrencias(data.recorrencias || []);
      } else {
        toast.error('Erro ao buscar recorrências.');
      }
    } catch (error) {
      console.error('Erro ao buscar recorrências:', error);
      toast.error('Erro ao buscar recorrências.');
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('Nenhum arquivo selecionado.');
      return;
    }

    setSelectedFile(file);
    const fileType = selectedBank === 'BancoItau' ? 'pdf' : 'csv';
    setFileType(fileType);

    if (fileType === 'pdf') {
      await extractTableFromPDF(file);
    } else if (fileType === 'csv') {
      await extractTableFromCSV(file);
    }
  };

  const extractTableFromPDF = async (file) => {
    const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
    const pdf = await loadingTask.promise;
    const totalPages = pdf.numPages;
    const allData = [];
  
    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const textContent = await page.getTextContent();
      let currentRow = [];
      let foundData = false;
  
      textContent.items.forEach((item) => {
        const text = item.str.trim();
        if (isDate(text)) {
          if (currentRow.length === 3) {
            allData.push(currentRow);
          }
          currentRow = [text];
          foundData = true;
        } else if (foundData && !isValue(text)) {
          currentRow[1] = currentRow[1] ? `${currentRow[1]} ${text}` : text;
        } else if (isValue(text)) {
          currentRow[2] = text;
          foundData = false;
        }
      });
      if (currentRow.length === 3) {
        allData.push(currentRow);
      }
    }
  
    setTableData(allData);
  };

  const extractTableFromCSV = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split('\n');
        const allData = rows.map((row, index) => {
          const columns = row.split(';');
          const dataLancamento = columns[0]; 
          const descricao = columns[1];
          const valor = columns[2];

          if (dataLancamento === 'Data Lançamento' && descricao === 'Descrição' && valor === 'Valor') {
            return null;
          }

          if (!dataLancamento || !descricao || !valor) return null;
          const tipo = valor.startsWith('-') ? 'Despesa' : 'Receita';
          return [dataLancamento, descricao, valor, tipo];
        }).filter(Boolean);
        setTableData(allData);
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Erro ao extrair dados do arquivo CSV:', error);
    }
  };

  const isDate = (text) => /^\d{2}\/\d{2}\/\d{4}$/.test(text);
  const isValue = (text) => /^-?\d+,\d{2}$/.test(text);

  const handleDeleteRow = (index) => {
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
  };

  const handleCadastrarLote = async () => {
    if (!selectedCarteira || !selectedBank) {
      toast.error('Selecione uma carteira e um banco.');
      return;
    }

    const apiUrl = `/api/users/${userId}/lancamentos`;
    try {
      const requests = tableData.map((row) => {
        const valor = row[2] ? parseFloat(row[2].replace('.', '').replace(',', '.')) : 0;
        const formattedDate = row[0] ? formatDate(row[0]) : 'Data não disponível';
        const descricao = row[1] || 'Descrição não disponível';
        const tipo = valor < 0 ? 'Despesa' : 'Receita';

        return fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tipo_lancamento: tipo,
            valor: Math.abs(valor),
            data: formattedDate,
            descricao: descricao,
            carteira: selectedCarteira,
            categoria: 'SEM CATEGORIA',
            forma_de_pagamento: 'SEM FORMA DE PAGAMENTO',
            moeda: 'BRL',
            novo_saldo: 0,
            recorrente: false,
          }),
        });
      });

      await Promise.all(requests);
      toast.success('Lançamentos cadastrados com sucesso!');
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao cadastrar lançamentos:', error);
      toast.error('Erro ao cadastrar lançamentos.');
    }
  };

  const formatDate = (date) => {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  };

  const handleImport = () => {
    setShowModal(true);
  };

  const handleRecurrenceClick = () => {
    fetchRecorrencias();
    setShowRecurrenceModal(true);
  };

  const handleEditRecurrence = (recorrencia) => {
    const formattedEndDate = new Date(recorrencia.data_fim).toISOString().split('T')[0]; // Formatar para YYYY-MM-DD
    setSelectedRecurrence(recorrencia);
    setNewEndDate(formattedEndDate);
    setShowEditModal(true);
  };

  const handleSaveRecurrence = async () => {
    try {
      const response = await fetch(`/api/recorrencias/${selectedRecurrence.id_lancamento_recorrente}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data_fim: newEndDate }),
      });
      if (response.ok) {
        toast.success('Data final atualizada com sucesso!');
        fetchRecorrencias();
        setShowEditModal(false);
      } else {
        toast.error('Erro ao atualizar a recorrência.');
      }
    } catch (error) {
      console.error('Erro ao atualizar a recorrência:', error);
      toast.error('Erro ao atualizar a recorrência.');
    }
  };

  const handleOpenDeleteConfirmModal = (recorrencia) => {
    setRecurrenceToDelete(recorrencia);
    setShowDeleteConfirmModal(true);
  };

  const handleDeleteRecurrence = async () => {
    try {
      const response = await fetch(`/api/recorrencias/${recurrenceToDelete.id_lancamento_recorrente}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        toast.success('Recorrência excluída com sucesso!');
        fetchRecorrencias();
        setShowDeleteConfirmModal(false); // Fechar o modal após exclusão
      } else {
        toast.error('Erro ao excluir a recorrência.');
      }
    } catch (error) {
      console.error('Erro ao excluir a recorrência:', error);
      toast.error('Erro ao excluir a recorrência.');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFile(null);
    setTableData([]);
  };

  const handleCloseRecurrenceModal = () => {
    setShowRecurrenceModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleCloseDeleteConfirmModal = () => {
    setShowDeleteConfirmModal(false);
  };

  const formatCurrency = (value, currency) => {
    try {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(value);
    } catch (e) {
      console.error('Código de moeda inválido:', currency);
      return value;
    }
  };



  //Editar Lançamentos
  const handleEditLancamento = (lancamento) => {
    setLancamentoToEdit(lancamento);
    setShowLancamentoEditModal(true); // Abrir o modal de edição
  };

  const handleSaveLancamento = (updatedLancamento) => {
    setLancamentos(prevLancamentos => 
      prevLancamentos.map(l => l.ID_LANCAMENTO === updatedLancamento.ID_LANCAMENTO ? updatedLancamento : l)
    );
    setShowLancamentoEditModal(false); // Fechar o modal após salvar
  };






  //Excluir Lançamentos simples e com recorrencia.
  //inicio
  const handleDeleteLancamento = async (lancamento) => {
    try {
      const userId = localStorage.getItem('userId');
      
      if (!userId) {
        console.error('Erro: userId não encontrado no localStorage');
        return;
      }
  
      // Consulta para verificar se o lançamento é recorrente
      const response = await fetch(`/api/users/${userId}/lancamentos/${lancamento.ID_LANCAMENTO}`);
      const data = await response.json();
  
      if (data.recorrente) {
        setIsRecorrente(true);
        setLancamentoToDelete(lancamento);
        setShowDeleteModal(true); // Abrir o modal
      } else {
        setIsRecorrente(false);
        setLancamentoToDelete(lancamento);
        setShowDeleteModal(true); // Abrir o modal
      }
    } catch (error) {
      console.error('Erro ao verificar o lançamento:', error);
    }
  };
  

  const handleConfirmDelete = async () => {
    if (lancamentoToDelete) {
      await excluirLancamento(lancamentoToDelete.ID_LANCAMENTO, isRecorrente);
      setShowDeleteModal(false); // Fechar o modal após a exclusão
      setLancamentoToDelete(null); // Limpar o estado do lançamento
    }
  };
  

  const excluirLancamento = async (idLancamento, isRecorrente) => {
    try {
      const userId = localStorage.getItem('userId');

      const url = isRecorrente 
        ? `/api/users/${userId}/lancamentos/${idLancamento}/recorrente`
        : `/api/users/${userId}/lancamentos/${idLancamento}`;
  
      const response = await fetch(url, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        console.log('Lançamento excluído com sucesso!');
  
        // Atualizando a lista de lançamentos na interface após a exclusão
        setLancamentos(prevLancamentos => 
          prevLancamentos.filter(lancamento => lancamento.ID_LANCAMENTO !== idLancamento)
        );
      } else {
        console.error('Erro ao excluir o lançamento:', await response.json());
      }
    } catch (error) {
      console.error('Erro ao excluir o lançamento:', error);
    }
  };
  

 //fim 
  



  

  return (
    <div className="lancamentos-container">
      <h2>Lançamentos do Mês</h2>

      <div className="icons-container">
      <button className="icon-btn" onClick={handleAddNew} title="Adicionar Novo Lançamento">
        <FaPlus className="action-icon" />
      </button>

        <button className="icon-btn" onClick={handleImport} title="Importar Arquivos">
          <FaFileImport className="action-icon" />
        </button>
        <button className="icon-btn" onClick={handleRecurrenceClick} title="Ver Recorrências">
          <FaSync className="action-icon" />
        </button>
      </div>

      <LancamentoModal 
        isOpen={showLancamentoModal} 
        onClose={handleCloseLancamentoModal} 
        tipoLancamentoInicial="Receita" // ou "Despesa", dependendo da lógica
      />

      <table className="lancamentos-table">
      <thead>
          <tr>
            <th>Tipo</th>
            <th>Data</th>
            <th>Descrição</th>
            <th>Valor</th>
            <th>Carteira</th>
            <th>Forma de Pagamento</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {lancamentos.length === 0 ? (
            <tr>
              <td colSpan="7">Nenhum lançamento encontrado</td>
            </tr>
          ) : (
            lancamentos.map((lancamento) => (
              <tr key={lancamento.ID_LANCAMENTO}>
                <td
                  style={{
                    color:
                      lancamento.TIPO_LANCAMENTO === 'Receita'
                        ? 'green'
                        : lancamento.TIPO_LANCAMENTO === 'Despesa'
                        ? 'red'
                        : 'orange',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1.2em', // Ajuste o tamanho aqui, por exemplo, 1.5em
                  }}
                >
                  {lancamento.TIPO_LANCAMENTO === 'Receita' && (
                    <>
                      <FaArrowUp style={{ marginRight: '5px' }} /> Receita
                    </>
                  )}
                  {lancamento.TIPO_LANCAMENTO === 'Despesa' && (
                    <>
                      <FaArrowDown style={{ marginRight: '5px' }} /> Despesa
                    </>
                  )}
                  {lancamento.TIPO_LANCAMENTO === 'Despesa em Cartão' && (
                    <>
                      <FaCreditCard style={{ marginRight: '5px' }} /> Despesa em Cartão
                    </>
                  )}
                </td>
                <td>{new Date(lancamento.DATA).toLocaleDateString()}</td>
                <td>{lancamento.DESCRICAO}</td>
                <td
                  style={{
                    color:
                      lancamento.TIPO_LANCAMENTO === 'Receita' ? 'green' : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {formatCurrency(lancamento.VALOR, lancamento.moeda)}
                </td>
                <td>{lancamento.CARTEIRA}</td>
                <td>{lancamento.FORMA_DE_PAGAMENTO}</td>
                <td className="actions-cell">
                  <FaEdit
                    className="action-icon"
                    title="Editar Lançamento"
                    onClick={() => handleEditLancamento(lancamento)}
                  />
                  <FaTrash
                    className="action-icon"
                    title="Excluir Lançamento"
                    onClick={() => handleDeleteLancamento(lancamento)}
                  />
                </td>

              </tr>
            ))
          )}
        </tbody>
      </table>

      <Modal show={showModal} onHide={handleCloseModal} centered backdrop={true} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>Importar Arquivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Selecione o Banco</label>
            <select
              className="form-control"
              value={selectedBank}
              onChange={(e) => setSelectedBank(e.target.value)}
            >
              <option value="">Selecione...</option>
              <option value="BancoItau">Banco Itau</option>
              <option value="BancoInter">Banco Inter</option>
            </select>
          </div>

          {selectedBank && (
            <input
              type="file"
              accept={selectedBank === 'BancoItau' ? '.pdf' : '.csv'}
              onChange={handleFileChange}
            />
          )}

          {carteiras.length > 0 && (
            <div className="form-group mt-3">
              <label>Selecione a Carteira</label>
              <select className="form-control" value={selectedCarteira} onChange={(e) => setSelectedCarteira(e.target.value)}>
                <option value="">Selecione...</option>
                {carteiras.map((carteira) => (
                  <option key={carteira.id} value={carteira.nome}>
                    {carteira.nome}
                  </option>
                ))}
              </select>
            </div>
          )}

          {selectedFile && tableData.length > 0 && (
            <table className="pdf-table">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Data</th>
                  <th>Descrição</th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => {
                  const valor = parseFloat(row[2].replace('.', '').replace(',', '.'));
                  const tipo = valor < 0 ? 'Despesa' : 'Receita';
                  return (
                    <tr key={index}>
                      <td>{tipo}</td>
                      <td>{row[0]}</td>
                      <td>{row[1]}</td>
                      <td>{row[2]}</td>
                      <td>
                        <FaTrash className="action-icon" onClick={() => handleDeleteRow(index)} title="Excluir" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCadastrarLote} className="btn btn-success">
            <FaSave /> Cadastrar Lançamentos
          </button>
          <button onClick={handleCloseModal} className="btn btn-secondary">Fechar</button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Recorrências */}
      <Modal show={showRecurrenceModal} onHide={handleCloseRecurrenceModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Lançamentos Recorrentes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {recorrencias.length === 0 ? (
            <p>Nenhum lançamento recorrente encontrado.</p>
          ) : (
            <table className="recorrencia-table">
              <thead>
                <tr>
                  <th>Frequência</th>
                  <th>Próximo Lançamento</th>
                  <th>Data Final</th>
                  <th>Tipo</th>
                  <th>Valor</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {recorrencias.map((recorrencia) => (
                  <tr key={recorrencia.id_lancamento_recorrente}>
                    <td>{recorrencia.frequencia}</td>
                    <td>{new Date(recorrencia.proximo_lancamento).toLocaleDateString()}</td>
                    <td>{new Date(recorrencia.data_fim).toLocaleDateString()}</td>
                    <td>{recorrencia.TIPO_LANCAMENTO}</td>
                    <td>{formatCurrency(recorrencia.VALOR, 'BRL')}</td>
                    <td>
                      <FaEdit
                        className="action-icon"
                        onClick={() => handleEditRecurrence(recorrencia)}
                        title="Editar"
                      />
                      <FaTrash
                        className="action-icon"
                        onClick={() => handleOpenDeleteConfirmModal(recorrencia)}
                        title="Excluir"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseRecurrenceModal} className="btn btn-secondary">Fechar</button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Edição de Recorrências */}
      <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar Recorrência</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRecurrence && (
            <div>
              <p>Frequência: {selectedRecurrence.frequencia}</p>
              <p>Próximo Lançamento: {new Date(selectedRecurrence.proximo_lancamento).toLocaleDateString()}</p>
              <div className="form-group">
                <label>Data Final</label>
                <input
                  type="date"
                  className="form-control"
                  value={newEndDate}
                  onChange={(e) => setNewEndDate(e.target.value)} // Correção aqui
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleSaveRecurrence} className="btn btn-success">
            <FaSave /> Salvar
          </button>
          <button onClick={handleCloseEditModal} className="btn btn-secondary">Fechar</button>
        </Modal.Footer>
      </Modal>

      {/* Modal de confirmação */}
      <ConfirmationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        message={isRecorrente ? "Este lançamento tem recorrência. Deseja excluir o lançamento e a recorrência?" : "Deseja excluir este lançamento?"}
      />

      {/* Modal de edição */}
      <LancamentoEditModal
        show={showLancamentoEditModal}
        onClose={() => setShowLancamentoEditModal(false)}
        lancamento={lancamentoToEdit}
        onSave={handleSaveLancamento}
        userId={userId}
      />

      {/* Modal de confirmação de exclusão */}
      <Modal show={showDeleteConfirmModal} onHide={handleCloseDeleteConfirmModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Tem certeza de que deseja excluir esta recorrência?</p>
          <p>Esta ação não pode ser desfeita.</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleDeleteRecurrence} className="btn btn-danger">
            Excluir
          </button>
          <button onClick={handleCloseDeleteConfirmModal} className="btn btn-secondary">
            Cancelar
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Lancamentos;